import React from "react"
import { MainLayout } from "../shared/main-layout"

export const PageNotFound = () => {
    return (
        <MainLayout>
                <div style={{position:'relative', width:'100%', marginTop:'10%', flex:1, alignItems:'center', justifyContent:'center', alignContent:'center', height:'80vh'}}>
                    <div className="text-center">
                        <h1 className="big-404"  style={{color:'red'}}><b>404</b></h1>
                        <h3 className="text-white" style={{color:'white'}}>oops ! ... Page not found</h3>
                        <p className="text-white">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
                        <a href="index-2.html" className="btn btn-primary">homepage</a>
                    </div>
                </div>
        </MainLayout>
    )
}