import React, { useState, useRef, useCallback, useEffect } from 'react';
import Moment from 'react-moment';
import { ThunkConfig } from '../../redux/config';
import { Utils } from '../../core/utils';
import { ProtectdPageWrapper } from '../../pages/_wrapper';
import CustomAlert from '../../shared/custom-alert';
import CustomDialog from '../../shared/custom-dialog';

export function ManageDeposits() {
    const [messages, setMessages] = useState<any>();
    const [isBusy, setBusy] = useState(false);
    const investments = localStorage.getItem("getInvestments");
    const [investmentList, setInvestmentList] = useState<any[]>(investments ? JSON.parse(investments) : [])
    const [selectedDepositId, setSelectedDepositId] = useState("")

    const GetInvestments = useCallback(async () => {
        const response = await ThunkConfig.HTTPGetRequest(`invests/get-all`);
        if (response) {
            localStorage.setItem("getInvestments", JSON.stringify(response))
            setInvestmentList(response)
        }
    }, [setInvestmentList])

    useEffect(() => {
        GetInvestments();
    }, [GetInvestments])

    const alertRef = useRef(null);
    const dialogRef = useRef(null);

    const OnDeleteRequest = (Id, UserId, PlanId, Amount) => {
        setSelectedDepositId(Id);
        let newMessage = `${UserId}'s deposit for ${PlanId} with amount: ${Utils.Currency.format(Amount)} will be deleted. \n\nAre you sure?`;
        setMessages(newMessage);
        dialogRef.current.toggleDialog();
    }

    const DeleteInvestment = async () => {
        setBusy(true);

        const response = await ThunkConfig.HTTPPostRequest(`invests/delete?id=${selectedDepositId}`, null);
        if (response && response?.statusCode === 200) {
            await GetInvestments();
        }
        setBusy(false);
        dialogRef.current.toggleDialog();
        Utils.AlertToast(response?.message, "info", "Okay!", true)
    }

    const ConfirmInvestment = useCallback(async (id: any, userId: any) => {
        setBusy(true);

        const response = await ThunkConfig.HTTPPostRequest(`invests/confirm-investment?id=${id}&userId=${userId}`, null);
        if (response && response?.statusCode === 200) {
            await GetInvestments();
        }
        setBusy(false);
        Utils.AlertToast(response?.message, "info", "Okay!", true)
    }, [GetInvestments])

    return (
        <ProtectdPageWrapper title='Manage Deposits'>

            <section className="shop-cart" style={{ margin: 0 }}>
                <div className="container">
                    <div className="col-xs-12 table-responsive">
                        <table className="table order text-center" >
                            <thead>
                                <tr>
                                    <th style={{ paddingLeft: 20 }}>S/N</th>
                                    <th style={{ minWidth: '200px' }}>Transaction Id</th>
                                    <th>User</th>
                                    <th>Plan</th>
                                    <th>Amount</th>
                                    <th>Profits</th>
                                    <th>Status</th>
                                    <th colSpan={4}>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    investmentList?.map((item: any, index) => {
                                        return (
                                            <tr key={index} style={{ color: 'black' }}>
                                                <td className="text-left" style={{ paddingLeft: 20 }}><span className="price">{index + 1}.</span></td>
                                                <td className="text-left"><span className="price">{item?.TransactionId}</span></td>
                                                <td className="text-left"><span className="price">{item?.UserId}</span></td>
                                                <td className="text-left"><span className="price">{item?.PlanId}</span></td>
                                                <td className="text-left"><span className="price">{Utils.Currency.format(item?.Amount ? item?.Amount : 0)}</span></td>
                                                <td className="text-left"><span className="price">{Utils.Currency.format(item?.Profits ? item?.Profits : 0)}</span></td>
                                                <td className="text-left"><span className="price">{item.Status}</span></td>
                                                <td className="text-left"><span className="price"><Moment fromNow>{item.ActionDate}</Moment></span></td>
                                                <td className="text-left">
                                                    <button disabled={item.Status === "Completed" || isBusy} onClick={() => ConfirmInvestment(item.Id, item.UserId)} className="btn btn-success btn-order pull-right">{isBusy ? "Please wait..." : item.Status !== "Completed" ? "Confirm Investment" : "Confirmed"}</button>
                                                </td>
                                                <td className="text-left">
                                                    <button disabled={isBusy} onClick={() => OnDeleteRequest(item.Id, item.UserId, item?.PlanId, item.Amount)} className="btn btn-warning btn-order pull-right">Delete</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {investmentList.length === 0 && <h3 className="text-center" style={{ color: 'silver' }}>No deposits yet</h3>}
                    </div>
                </div>
            </section>

            <CustomDialog ref={dialogRef} >
                <h4 className='text-success text-center mb-5 mt-3'>{messages}</h4>
                <div className='row'>
                    <div className='col-md-6'><button disabled={isBusy} className='btn btn-default' onClick={() => dialogRef.current.toggleDialog()}>No</button></div>
                    <div className='col-md-6 text-right'>
                        <button disabled={isBusy} onClick={() => DeleteInvestment()} type="submit" className="btn btn-primary btn-order pull-right">{isBusy ? "Deleting..." : "Yes"}</button>
                    </div>
                </div>
            </CustomDialog>

            <CustomAlert ref={alertRef}>
                <h4>{messages}</h4>
            </CustomAlert>

        </ProtectdPageWrapper>
    );
}