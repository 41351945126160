import React, { useState, useRef, useCallback, useEffect, FormEvent } from 'react';
import Switch from '@mui/material/Switch';
import { TextField, FormControlLabel, Button, FormControl, InputLabel, Input, InputAdornment, IconButton } from '@mui/material';
import { Edit, Delete } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ThunkConfig } from '../../redux/config';
import CustomAlert from '../../shared/custom-alert';
import CustomDialog from '../../shared/custom-dialog';
import CustomModal from '../../shared/custom-modal';
import { Utils } from '../../core/utils';
import { ProtectdPageWrapper } from '../../pages/_wrapper';
import Moment from 'react-moment';
import Countries from "../../data/countries.json"

const wAddressModel = { UserId: '', Coin: '', Address: '' }

export function ManageUsers() {

    const navigate = useNavigate();

    const [messages, setMessages] = useState<any>();
    const [isBusy, setBusy] = useState(false);
    const [isUserWallet, setUserWallet] = useState(false);
    const [values, setValues] = useState<any>();
    const [wAddress, setWAddress] = useState(wAddressModel);
    const [manageWallet, setManageWallet] = useState<any>();
    const [walletDetail, setWalletDetails] = useState<any>();

    const [selectedUser, setSelectedUser] = useState<any>();

    const users = localStorage.getItem("userList")
    const [userList, setUserList] = useState<any[]>(users ? JSON.parse(users) : [])

    const [walletAddress, setWalletAddress] = useState<any[]>()

    const alertRef = useRef(null);
    const dialogRef = useRef(null);
    const modalRef = useRef(null);

    const OnOpenModal = () => {
        setValues(null);
        modalRef.current.toggleModal();
    }

    const GetUsers = useCallback(async () => {
        try {
            const response = await ThunkConfig.HTTPGetRequest("account/get-users");
            if (response) {
                setUserList(response)
            }
        } catch (error) {
        }
    }, [])

    const GetWalletAddress = useCallback(async () => {
        try {
            const userId = localStorage.getItem("selectedUser");
            if (userId) {
                setWalletAddress([])
                const response = await ThunkConfig.HTTPGetRequest(`WalletAddress/get-selected-user-wallet-address?userId=${userId}`);
                if (response) {
                    setWalletAddress(response)
                }
            }
        } catch (error) {
        }
    }, [setWalletAddress])

    const OnEditAccount = (item) => {
        setBusy(true);
        setValues(null);
        setValues(item);
        modalRef.current.toggleModal();
        setBusy(false);
    }

    const OnDeleteRequest = (item, name) => {
        setBusy(true);
        let newMessage = (<><b className='mb-4'>{name}, will be deleted.</b> <br /> <em className='text-danger'>Are you sure?</em></>);
        setMessages(newMessage);
        setValues(item);
        setBusy(false);
        dialogRef.current.toggleDialog();
    }

    const OnDelete = useCallback(async () => {
        try {
            setBusy(true);
            const response = await ThunkConfig.HTTPPostRequest(`account/delete-user?id=${values?.Id}`, null);
            if (response && response?.statusCode === 200) {
                await GetUsers();
            }
            setBusy(false);
            Utils.AlertToast(response?.message, "info", "Okay!", true)
            dialogRef.current.toggleDialog();
            alertRef.current.toggleAlert();
        } catch (error) {
        }
    }, [GetUsers, values])

    const OnToggle = useCallback(async (id) => {
        try {
            setBusy(true);
            const response = await ThunkConfig.HTTPPostRequest(`account/toggle-user?id=${id}`, null);
            if (response && response?.statusCode === 200) {
                await GetUsers();
            }
            setBusy(false);
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
        }
    }, [GetUsers])

    const SaveData = useCallback(async () => {
        try {
            setBusy(true);
            const response = await ThunkConfig.HTTPPostRequest(`account/signup`, values);
            if (response && response?.statusCode === 200) {
                setValues(null)
                await GetUsers();
            }
            setBusy(false);
            setMessages(response?.data?.message);
            modalRef.current.toggleModal();
            alertRef.current.toggleAlert();
        } catch (error) {
        }
    }, [GetUsers, values])

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const onPostLogin = useCallback(async (userId: any) => {
        try {
            setBusy(true);
            const response = await ThunkConfig.HTTPPostRequest(`account/admin-user-login?userId=${userId}`, null);
            if (response && response?.statusCode === 200) {
                localStorage.setItem("accessToken", response?.data?.token)
                localStorage.setItem("role", Utils.EncryptData(response?.data?.role))
                localStorage.setItem("userDetails", JSON.stringify(response?.data))
                localStorage.setItem("sidebarStatus", "Visible");

                if (response.data.role !== "Admin") {
                    const userDataReport = await ThunkConfig.HTTPGetRequest("DashboardDataReport/get-user-data-report");
                    if (userDataReport) {
                        localStorage.setItem("dataReport", JSON.stringify(userDataReport))
                        // await IndexedDB.AddData(IndexedDB.StoreNames.dashboardData, userDataReport, IndexedDB.StoreKeys.dashboardData)
                    }
                } else {
                    const adminDataReport = await ThunkConfig.HTTPGetRequest("DashboardDataReport/data-report");
                    if (adminDataReport) {
                        localStorage.setItem("dataReport", JSON.stringify(adminDataReport))
                        // await IndexedDB.AddData(IndexedDB.StoreNames.dashboardData, adminDataReport, IndexedDB.StoreKeys.dashboardData)
                    }
                }

                const walletDetails = await ThunkConfig.HTTPGetRequest(`wallet/get-user-wallet`);
                if (walletDetails) {
                    localStorage.setItem("walletDetails", JSON.stringify(walletDetails))
                }
                console.log("login-response: ", response)
                return navigate("/dashboard")
            } else {
                Utils.AlertToast(response?.message, "info", "Okay!", true)
            }
            setBusy(false);
        } catch (error: any) {
            setBusy(false);
            Utils.AlertToast(error?.message, "info", "Okay!", true)
        }
    }, [])

    const OnViewWallet = useCallback(async (item: any, userId: any) => {
        try {
            setSelectedUser(userId);
            localStorage.setItem("selectedUser", userId)
            setWalletAddress([])
            setWalletDetails(null)

            setValues(null);
            setValues(item);
            if (userId) {
                const walletDetails = await ThunkConfig.HTTPGetRequest(`wallet/get-user-wallet-detail?userId=${userId}`);
                if (walletDetails) {
                    setWalletDetails(walletDetails)
                }
                const wallet = await ThunkConfig.HTTPGetRequest(`WalletAddress/get-selected-user-wallet-address?userId=${userId}`);
                if (wallet) {
                    setWalletAddress(wallet);
                }
                await GetWalletAddress();
                setUserWallet(!isUserWallet)
            }
        } catch (error) {
        }
    }, [GetWalletAddress, isUserWallet])

    const SaveWalletAddress = useCallback(async (event: FormEvent) => {
        event.preventDefault();
        try {
            setBusy(true)
            setWAddress((prev) => ({ ...prev, UserId: localStorage.getItem("selectedUser") }))
            const userId = localStorage.getItem("selectedUser");
            wAddress.UserId = userId
            const response = await ThunkConfig.HTTPPostRequest(`WalletAddress/save-data`, wAddress);
            if (response && response.statusCode === 200) {
                setWAddress(wAddressModel);
                await GetWalletAddress();
            }
            Utils.AlertToast(response?.message, "info", "Okay!", true)
            setBusy(false)
        } catch (error: any) {
            Utils.AlertToast(error?.message, "error", "Okay!", true)
        }
    }, [GetWalletAddress, setWAddress, wAddress])

    const OnFundWallet = useCallback(async (event: FormEvent) => {
        event.preventDefault();
        try {
            setBusy(true)
            manageWallet.UserId = localStorage.getItem("selectedUser");
            manageWallet.UserName = selectedUser;

            const response = await ThunkConfig.HTTPPostRequest(`wallet/manage-fund-wallet`, manageWallet);
            if (response != null && response.statusCode === 200) {
                setManageWallet(null);
                setUserWallet(!isUserWallet);
            }
            setBusy(false)
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
        }
    }, [isUserWallet, manageWallet, selectedUser])

    const OnDeleteWallet = useCallback(async (id: string) => {
        try {
            const response = await ThunkConfig.HTTPPostRequest(`WalletAddress/delete-wallet-address?id=${id}`, null);
            if (response.statusCode === 200) {
                Utils.AlertToast(response?.message, "error", "Okay!", true)
                await GetWalletAddress();
            }
        } catch (error) {
        }
    }, [GetWalletAddress])

    useEffect(() => {
        GetUsers();
        GetWalletAddress();
    }, [GetUsers, GetWalletAddress, setSelectedUser, selectedUser])

    const [searchQuery, setSearchQuery] = useState<any>()

    return (
        <ProtectdPageWrapper title='Manage Users'>

            <div className='container mb-5' style={{ marginTop: '50px' }}>
                <div className='row mb-5' style={{ marginBottom: '10px' }}>
                    <div className='col-md-6'>
                        {!isUserWallet ? <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ borderRadius: 10, padding: '10px', width: '70%' }} placeholder='search user' /> : null}
                    </div>
                    {
                        !isUserWallet ?
                            <div className='col-md-6 text-right'><button disabled={isBusy} className='btn btn-success' onClick={() => OnOpenModal()} style={{ width: 100 }}>New</button></div>
                            :
                            <div className='col-md-6 text-right'><button disabled={isBusy} className='btn btn-danger' onClick={() => setUserWallet(!isUserWallet)}>Close</button></div>
                    }
                </div>

                {
                    isUserWallet ?
                        <div className="container">
                            <div className="row" style={{ paddingBottom: '100px', marginBottom: '120px' }}>
                                <div className="col-xs-12">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-4">
                                            <div className="form-group">
                                                <input type="text" value={values?.Name} onChange={(e) => setValues((prev) => ({ ...prev, Name: e.target.value }))} placeholder="FIRST NAME" className="form-control" />
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-md-4">
                                            <div className="form-group">
                                                <input type="email" value={values?.Email} onChange={(e) => setValues((prev) => ({ ...prev, Email: e.target.value }))} placeholder="EMAIL" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-md-4">
                                            <div className="form-group">
                                                <input type="text" value={values?.UserName} onChange={(e) => setValues((prev) => ({ ...prev, UserName: e.target.value }))} placeholder="USER NAME" className="form-control" />
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-md-4">
                                            <div className="form-group">
                                                <input type="text" value={values?.UserRole} onChange={(e) => setValues((prev) => ({ ...prev, UserRole: e.target.value }))} placeholder="ROLE" className="form-control" />
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-md-4">
                                            <div className="form-group">
                                                <select className="form-control" style={{ color: 'white' }}>
                                                    <option value={values?.Country} onChange={(e) => setValues((prev) => ({ ...prev, Country: e.target }))} selected disabled>please select country</option>
                                                    {
                                                        Countries.map((country, index) => {
                                                            <option value="AX">Åland Islands</option>
                                                            return (<option key={index} value={country.name}>{country.name} ({country.code})</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                                        <div className="col-md-12" style={{ marginTop: 20 }}>
                                            <div className="form-group">
                                                <label>WALLET ADDRESS</label>
                                                <hr style={{ margin: 0, padding: 0 }} />
                                            </div>
                                        </div>

                                        <div className="col-lg-12" style={{ margin: 0, padding: 0 }}>
                                            <form className="row" onSubmit={(event) => SaveWalletAddress(event)}>
                                                <div className="col-xs-12 col-md-4">
                                                    <div className="form-group">
                                                        <select
                                                            onChange={(e) => { setWAddress((prev) => ({ ...prev, Coin: e.target.value })) }}
                                                            value={wAddress?.Coin}
                                                            required
                                                            className="form-control" style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                                                            <option selected disabled value="" style={{ color: 'silver' }}>-- select wallet type --</option>
                                                            <option value="Bitcoin">Bitcoin</option>
                                                            <option value="Ethereum">Ethereum</option>
                                                            <option value="Tether USDT">Tether USDT</option>
                                                            <option value="Binance">Binance</option>
                                                            {/* <option value="Reinvest">Reinvest with balance</option> */}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-md-7">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            onChange={(e) => { setWAddress((prev) => ({ ...prev, Address: e.target.value })) }}
                                                            value={wAddress?.Address}
                                                            placeholder="WALLET ADDRESS"
                                                            className="form-control"
                                                            required />
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-md-1">
                                                    <button type="submit" className="btn btn-primary btn-order pull-right">Save</button>
                                                </div>
                                            </form>
                                        </div>

                                        <div className="col-md-12 table-responsive" style={{ marginTop: 30 }}>
                                            <table className="table order text-center" >
                                                <thead>
                                                    <tr>
                                                        <th style={{ paddingLeft: 20 }}>S/N</th>
                                                        <th>Wallet Type</th>
                                                        <th>Address</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        walletAddress?.map((item, index) => {
                                                            console.log(`deposit-${index + 1}}: `, item)
                                                            return (
                                                                <tr key={index} style={{ color: 'black' }}>
                                                                    <td className="text-left" style={{ paddingLeft: 20 }}><span className="price">{index + 1}.</span></td>
                                                                    <td className="text-left"><span className="price">{item?.coin}</span></td>
                                                                    <td className="text-left"><span className="price">{item?.address}</span></td>
                                                                    <td className="text-left">
                                                                        <label onClick={() => OnDeleteWallet(item?.id)} style={{ color: 'red', cursor: 'pointer' }}>Delete</label>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {!walletAddress || walletAddress?.length === 0 ? <h3 className="text-center" style={{ color: 'silver' }}>No wallet address for the selected user!</h3> : null}
                                        </div>
                                    </div>

                                    <form onSubmit={(event) => OnFundWallet(event)} className="row" style={{ marginTop: 20, marginBottom: 100 }}>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>MANAGE FUNDS</label>
                                                <hr style={{ margin: 0, padding: 0 }} />
                                            </div>
                                        </div>

                                        <div className="col-md-5" style={{ marginTop: "30px" }}>
                                            <label>Wallet Balance</label>
                                            <h1 style={{ color: 'green', fontWeight: 'bold' }}>{Utils.Currency.format(walletDetail ? walletDetail?.AvailableBalance : 0)}</h1>
                                        </div>

                                        <div className="col-md-7">
                                            <div className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label active">Select Wallet:</label>
                                                    <div className="col-sm-8">
                                                        <select value={manageWallet?.WalletType} onChange={(e) => setManageWallet({ ...manageWallet, WalletType: e.target.value })} className="form-control" style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                                                            <option value="" selected disabled>Choose Option</option>
                                                            <option value="Bitcoin">Bitcoin</option>
                                                            <option value="Other Wallet">Other Wallet</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label active">Select Fund Type:</label>
                                                    <div className="col-sm-8">
                                                        <select value={manageWallet?.FundType} onChange={(e) => setManageWallet({ ...manageWallet, FundType: e.target.value })} className="form-control" style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                                                            <option value="" selected disabled>Choose Option</option>
                                                            <option value="Add">Add</option>
                                                            <option value="Add">Substract</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label active">Send fund to:</label>
                                                    <div className="col-sm-8">
                                                        <select value={manageWallet?.SendFundTo} onChange={(e) => setManageWallet({ ...manageWallet, SendFundTo: e.target.value })} className="form-control" style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                                                            <option value="" selected disabled>Choose Option</option>
                                                            <option value="Balance">Balance</option>
                                                            <option value="Profits">Profits</option>
                                                            <option value="ReferralBonus">Referral Bonus</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label active">Amount: </label>
                                                    <div className="col-sm-8">
                                                        <input value={manageWallet?.Amount} onChange={(e: any) => setManageWallet({ ...manageWallet, Amount: Number(e.target.value?.replaceAll(/[^0-9]/g, "")) })} className="form-control" type="search" placeholder="Enter Amount" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <button type="submit" className="btn btn-primary btn-order pull-right">Submit</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col-xs-12 table-responsive">
                            <table className="table order text-center" >
                                <thead>
                                    <tr>
                                        <th style={{ paddingLeft: 20 }} colSpan={2}>S/N</th>
                                        <th style={{ minWidth: 150 }}>Name</th>
                                        <th style={{ minWidth: 150 }}>User Name</th>
                                        <th style={{ minWidth: 250 }}>Email</th>
                                        <th style={{ minWidth: 150 }}>User Role</th>
                                        <th style={{ minWidth: 150 }}>Status</th>
                                        <th>Password</th>
                                        <th style={{ minWidth: 150 }}>Admin Login</th>
                                        <th style={{ minWidth: 150 }}>Date</th>
                                        <th colSpan={2}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userList?.filter(x => searchQuery ? x.Name?.toLowerCase()?.includes(searchQuery.toLowerCase()) || x.UserName?.toLowerCase()?.includes(searchQuery.toLowerCase()) || x.Email?.toLowerCase()?.includes(searchQuery.toLowerCase()) : x)?.map((item: any, index) => {
                                            return (
                                                <tr key={index} style={{ color: 'black' }}>
                                                    <td className="text-left" style={{ paddingLeft: 20 }}><span className="price">{index + 1}.</span></td>

                                                    <td className="text-left">
                                                        <Visibility style={{ width: 30, height: 30, cursor: 'pointer' }} color="success" onClick={() => OnViewWallet(item, item.Id)} />
                                                    </td>

                                                    <td className="text-left"><span className="price" onClick={() => OnViewWallet(item, item.Id)}>{item?.Name}</span></td>
                                                    <td className="text-left"><span className="price">{item?.UserName}</span></td>
                                                    <td className="text-left"><span className="price">{item?.Email}</span></td>
                                                    <td className="text-left"><span className="price">{item?.UserRole}</span></td>
                                                    <td className="text-left">
                                                        <span className="price"><FormControlLabel control={<Switch color='success' disabled={isBusy} checked={item.IsActive} onChange={() => OnToggle(item.Id)} />} label={item.IsActive ? "Active" : "Disabled"} /></span>
                                                    </td>
                                                    <td className="text-left">
                                                        <Button className="price" color='primary' style={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }} >Change</Button>
                                                    </td>
                                                    <td className="text-left">
                                                        <Button className="price" style={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }} color='success' onClick={() => onPostLogin(item.Id)}>Login</Button>
                                                    </td>

                                                    <td className="text-left"><span className="price"><Moment fromNow>{item.ActionDate}</Moment></span></td>

                                                    <td className="text-left">
                                                        <Edit style={{ width: 30, height: 30, cursor: 'pointer' }} className='mr-4' color="primary" onClick={() => OnEditAccount(item)} />
                                                    </td>
                                                    <td className="text-left">
                                                        <Delete style={{ width: 30, height: 30, cursor: 'pointer' }} color="error" onClick={() => OnDeleteRequest(item, item.Name)} />
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {userList.length === 0 && <h3 className="text-center" style={{ color: 'silver' }}>No user has been registered yet!</h3>}
                        </div>
                }

                <CustomModal ref={modalRef} width={'600px'} height={'200px'} title={'Manage User'} isBusy={false}>

                    <div className="row p-3 d-flex align-content-center align-items-center justify-content-between">

                        <TextField value={values?.Name} onChange={(e) => { setValues({ ...values, Name: e.target.value }) }} label="Full Name" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                        <TextField value={values?.Email} onChange={(e) => { setValues({ ...values, Email: e.target.value }) }} label="Email" type="email" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                        <TextField value={values?.BitcoinWalletAddress} onChange={(e) => { setValues({ ...values, BitcoinWalletAddress: e.target.value }) }} label="Bitcoin Wallet Address" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                        <TextField value={values?.SecreteQuestion} onChange={(e) => { setValues({ ...values, SecreteQuestion: e.target.value }) }} label="Secrete Question" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                        <TextField value={values?.SecreteQuestionAnswer} onChange={(e) => { setValues({ ...values, SecreteQuestionAnswer: e.target.value }) }} label="Secrete Question Answer" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                        <TextField value={values?.Username} onChange={(e) => { setValues({ ...values, Username: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" label="Username" variant="standard" />

                        <FormControl sx={{ width: '100%', mt: 2 }} variant="standard">
                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => { setValues({ ...values, Password: e.target.value }) }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        <div className='text-right' style={{ float: 'right', width: '100%' }}>
                            <button disabled={isBusy} onClick={() => SaveData()} type="submit" className="btn btn-primary btn-order pull-right">{isBusy ? "Saving..." : "Save"}</button>
                        </div>

                    </div>

                </CustomModal>

                <CustomDialog ref={dialogRef} >
                    <h4 className='text-success text-center mb-5 mt-3'>{messages}</h4>
                    <div className='row'>
                        <div className='col-md-6'><button className='btn btn-default' onClick={() => dialogRef.current.toggleDialog()}>No</button></div>
                        <div className='col-md-6 text-right'>
                            <button disabled={isBusy} onClick={() => OnDelete()} type="submit" className="btn btn-primary btn-order pull-right">{isBusy ? "Deleting..." : "Yes"}</button>
                        </div>
                    </div>
                </CustomDialog>
                <CustomAlert ref={alertRef}><h3 className='text-center'> {messages}</h3></CustomAlert>

            </div >
        </ProtectdPageWrapper >
    );
}