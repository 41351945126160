import React, { useState, useCallback, useEffect } from "react"
import { TextField, MenuItem, Paper, Button, Autocomplete, Checkbox } from '@mui/material';
import { Mail } from "@mui/icons-material";
import { ThunkConfig } from "../../redux/config";
import { Utils } from "../../core/utils";
import { ProtectdPageWrapper } from "../../pages/_wrapper";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const mailModel = { Title: '', Users: [], Message: '', }

export const MailingSystem = () => {

    const [values, setValues] = useState(mailModel);
    const [isBusy, setBusy] = useState(false);

    const users = localStorage.getItem("userList")
    const [userList, setUserList] = useState<any[]>(users ? JSON.parse(users) : [])

    const GetUsers = useCallback(async () => {
        try {
            const response = await ThunkConfig.HTTPGetRequest("account/get-users");
            if (response) {
                const usernames: string[] = response.map((user: any) => ({ UserName: user?.UserName, Email: user?.Email, Name: user.Name }));
                setUserList(usernames.sort((a: any, b: any) => a.UserName.localeCompare(b.UserName)))
            }
        } catch (error) {
        }
    }, [])

    const SendMail = useCallback(async () => {
        try {
            setBusy(true);
            const response = await ThunkConfig.HTTPPostRequest(`maillingSystem/send-mail`, values);
            if (response && response.statusCode === 200) {
                setValues(mailModel)
            }
            Utils.AlertToast(response?.message, "info", "Okay!", true)
            setBusy(false);
        } catch (error) {
            setBusy(false);
            Utils.AlertToast("Something went wrong, please try again.", "info", "Okay!", true)
        }
    }, [values])

    useEffect(() => {
        GetUsers()
    }, [GetUsers])


    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const handleAutoCompleteChange = (event, newValue) => {
        setValues((prev) => ({ ...prev, Users: newValue })); // Update selectedValues state
        // setSelectedValues(newValue); // Update selectedValues state
    };

    console.log("values: ", values)

    return (
        <ProtectdPageWrapper title='Mailing System'>
            <Paper elevation={3} style={{  padding: 20, width:'80%', margin:'auto' }}>
                <div className="row p-3 d-flex align-content-center align-items-center justify-content-between">
                    <div className="col-md-12">
                        <Autocomplete
                            multiple
                            onChange={handleAutoCompleteChange}
                            id="checkboxes-tags-demo"
                            options={userList}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option?.UserName}
                            style={{ width: '100%', fontSize: 18 }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginBottom: 8, fontSize: 18 }}
                                        checked={selected}
                                    />
                                    <p style={{ fontSize: 18, color:'black' }}>{option?.UserName} ({option?.Email})</p>
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField style={{ fontSize: 18, fontWeight: 'bold' }} {...params} label="Users" placeholder="select or search user" />
                            )}
                        />
                    </div>

                    <div className="col-md-12">
                        <TextField value={values?.Title} onChange={(e) => setValues({ ...values, Title: e.target.value })} sx={{ width: '100%', my: 3 }} id="standard-basic" label="Title" />
                    </div>

                    <div className="col-md-12">
                        <TextField multiline={true} rows={4} value={values?.Message} onChange={(e) => setValues({ ...values, Message: e.target.value })} sx={{ width: '100%', my: 3 }} id="standard-basic" label="Message" />
                    </div>

                    <div className="col-md-12">
                        <Button
                            color="primary"
                            onClick={() => SendMail()}
                            disabled={isBusy}
                            startIcon={<Mail />}
                            variant="contained"
                            sx={{ my: 2, float: 'right', px: 5, py: 1 }}
                        >
                            <span>Send</span>
                        </Button>
                    </div>

                </div>
            </Paper>


        </ProtectdPageWrapper >
    )
}