/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { FormEvent, useCallback, useMemo, useState } from "react"
import { SignupModel } from "../dtos/_index";
import { Utils } from "../core/utils";
import { ThunkConfig } from "../redux/config";

export const SignupServices = () => {

    const navigate = useNavigate();

    const [values, setValues] = useState(SignupModel);
    const [isBusy, setIsBusy] = useState(false);

    const HandleSignup = useCallback(async (event: FormEvent) => {
        event.preventDefault();
        try {
            setIsBusy(true);

            // await Utils.TaskDelay();

            const response = await ThunkConfig.HTTPPostRequest("account/signup", values);

            if (response.statusCode === 200) {
                return navigate("/account/login")

                // if (response.data.role !== "Admin") {
                //     const userDataReport = await ThunkConfig.HTTPGetRequest("DashboardDataReport/get-user-data-report");
                //     if (userDataReport) {
                //         localStorage.setItem("dataReport", JSON.stringify(userDataReport))
                //         // await IndexedDB.AddData(IndexedDB.StoreNames.dashboardData, userDataReport, IndexedDB.StoreKeys.dashboardData)
                //     }
                // } else {
                //     const adminDataReport = await ThunkConfig.HTTPGetRequest("DashboardDataReport/data-report");
                //     if (adminDataReport) {
                //         localStorage.setItem("dataReport", JSON.stringify(adminDataReport))
                //         // await IndexedDB.AddData(IndexedDB.StoreNames.dashboardData, adminDataReport, IndexedDB.StoreKeys.dashboardData)
                //     }
                // }

                // // await IndexedDB.AddData(IndexedDB.StoreNames.UserDetails, response?.data, IndexedDB.StoreKeys.UserDetails)
                // localStorage.setItem("accessToken", response?.data?.token)
                // localStorage.setItem("role", Utils.EncryptData(response?.data?.role))
                // localStorage.setItem("userDetails", JSON.stringify(response?.data))
                // localStorage.setItem("sidebarStatus", "Visible");

                // setIsBusy(false);

                // return navigate("/dashboard")
            } else {
                setIsBusy(false);
                Utils.AlertToast(response?.message, "error", "Okay!", true)
            }
        } catch (error) {
            setIsBusy(false);
            console.log("login-error: ", error)
            Utils.AlertToast("Something went wrong, please try again.", "error", "Ok", true)
        }
    }, [values])

    const LoginProps = {
        values, setValues,
        isBusy, setIsBusy,
        HandleSignup
    };


    return useMemo(() => ({ ...LoginProps }), [LoginProps]);
}