import React, { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error) {
        // Update state to indicate an error has occurred
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        //log error to the backend

        // Log the error to an error reporting service
        console.info('Error caught by ErrorBoundary:', error?.message, errorInfo?.componentStack);
    }

    render() {
        // Render children components normally
        return this.props.children;
    }
}

export default ErrorBoundary;
