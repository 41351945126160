import React from "react"
import { MainLayout } from "../shared/main-layout"
import { Link } from "react-router-dom"

export const AboutUs = () => {
    return (
        <MainLayout>
            <section className="banner-area">
                <div className="banner-overlay">
                    <div className="banner-text text-center">
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-xs-12">
                                    <h2 className="title-head">About <span>Us</span></h2>
                                    <hr />
                                    <ul className="breadcrumb">
                                        <li><a href="index-2.html"> home</a></li>
                                        <li>About</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-us">
                <div className="container">
                    <div className="row about-content">
                        <div className="col-sm-12 col-md-5 col-lg-6 text-center">
                            {/* <div className="btcwdgt-chart" data-bw-theme="dark"></div> */}
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h3 className="title-about">WE ARE {process.env.REACT_APP_APP_NAME}</h3>
                            <p className="about-text">A place for everyone who wants to simply invest in cryptocurrency. Our investment professionals invest savers funds in financial instruments and manage the investments to ensure optimum return. To ensure security of savers funds, a company registered with the Security and Exchange Commission (SEC) on behalf of savers. These assets are marked to market periodically to ensure savers are protected.</p>
                            <ul className="nav nav-tabs">
                                <li className="active"><a data-toggle="tab" href="#menu3">Our Mission</a></li>
                            </ul>
                            <div className="tab-content">
                                <div id="menu1" className="tab-pane fade in active">
                                    <p>We are here because we are passionate about open, transparent markets and aim to be a major driving force in widespread adoption of cryptocurrency. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="facts">
                <div className="container">
                    <div className="row text-center facts-content">
                        <div className="text-center heading-facts">
                            <h2>{process.env.REACT_APP_APP_NAME}<span> numbers</span></h2>
                            <p>leading cryptocurrency investment since day one of Bitcoin distribution</p>
                        </div>
                        <div className="col-xs-12 col-md-3 col-sm-6 fact">
                            <h3>$77.45B</h3>
                            <h4>market cap</h4>
                        </div>
                        <div className="col-xs-12 col-md-3 col-sm-6 fact fact-clear">
                            <h3>165k</h3>
                            <h4>daily transactions</h4>
                        </div>
                        <div className="col-xs-12 col-md-3 col-sm-6 fact">
                            <h3>1726</h3>
                            <h4>active accounts</h4>
                        </div>
                        <div className="col-xs-12 col-md-3 col-sm-6">
                            <h3>17</h3>
                            <h4>years on the market</h4>
                        </div>
                        <div className="col-xs-12 buttons">
                            <Link className="btn btn-primary btn-pricing" to="/investment-plans">See our Plans</Link>
                            <span className="or"> or </span>
                            <Link className="btn btn-primary btn-register" to="/account/register">Register Now</Link>
                        </div>
                    </div>
                </div>
            </section>
        </MainLayout>
    )
}