import React, { useState } from "react"
import { MainLayout } from "../shared/main-layout"
import { Utils } from "../core/utils"

const messageModel = {
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: ''
}

export const Contact = () => {

    const [isBusy, setIsBusy] = useState(false)

    const [values, setValues] = useState(messageModel)


    const HandleSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();
        setIsBusy(true);

        setTimeout(() => {
            setIsBusy(false);
            Utils.AlertToast("Message sent successfully, we'll get back to you", "info", "Okay", true)
            setValues(messageModel)
            // alert("Message sent successfully, we'll get back to you")
        }, 1000);
    }

    return (
        <MainLayout>
            <section className="banner-area">
                <div className="banner-overlay">
                    <div className="banner-text text-center">
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-xs-12">
                                    <h2 className="title-head">Get in <span>touch</span></h2>
                                    <hr />
                                    <ul className="breadcrumb">
                                        <li><a href="index-2.html"> home</a></li>
                                        <li>contact</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-8 contact-form">
                            <h3 className="col-xs-12">feel free to drop us a message</h3>
                            <p className="col-xs-12">Need to speak to us? Do you have any queries or suggestions? Please contact us about all enquiries including membership and volunteer work using the form below.</p>
                            <form onSubmit={(e) => HandleSubmit(e)}>
                                <div className="form-group col-md-6">
                                    <input className="form-control" value={values.firstName} onChange={(e) => setValues((prev) => ({ ...prev, firstName: e.target.value }))} placeholder="FIRST NAME" type="text" required />
                                </div>
                                <div className="form-group col-md-6">
                                    <input className="form-control" value={values.lastName} onChange={(e) => setValues((prev) => ({ ...prev, lastName: e.target.value }))} placeholder="LAST NAME" type="text" required />
                                </div>
                                <div className="form-group col-md-6">
                                    <input className="form-control" value={values.email} onChange={(e) => setValues((prev) => ({ ...prev, email: e.target.value }))} placeholder="EMAIL" type="email" required />
                                </div>
                                <div className="form-group col-md-6">
                                    <input className="form-control" value={values.subject} onChange={(e) => setValues((prev) => ({ ...prev, subject: e.target.value }))} placeholder="SUBJECT" type="text" required />
                                </div>
                                <div className="form-group col-xs-12">
                                    <textarea className="form-control" value={values.message} onChange={(e) => setValues((prev) => ({ ...prev, message: e.target.value }))} placeholder="MESSAGE" required></textarea>
                                </div>
                                <div className="form-group col-xs-12 col-sm-4">
                                    <button disabled={isBusy} className="btn btn-primary btn-contact" type="submit"> {isBusy ? "Please wait..." : "send message"}</button>
                                </div>
                                <div className="col-xs-12 text-center output_message_holder d-none">
                                    <p className="output_message"></p>
                                </div>
                            </form>
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <div className="widget">
                                <div className="contact-page-info">
                                    <div className="contact-info-box">
                                        <i className="fa fa-home big-icon"></i>
                                        <div className="contact-info-box-content">
                                            <h4>Address</h4>
                                            <p> {process.env.REACT_APP_OFFICE_ADDRESS}</p>
                                        </div>
                                    </div>
                                    <div className="contact-info-box">
                                        <i className="fa fa-phone big-icon"></i>
                                        <div className="contact-info-box-content">
                                            <h4>Phone Numbers</h4>
                                            <p>{process.env.REACT_APP_PHONUMBER}</p>
                                        </div>
                                    </div>
                                    <div className="contact-info-box">
                                        <i className="fa fa-envelope big-icon"></i>
                                        <div className="contact-info-box-content">
                                            <h4>Email Addresses</h4>
                                            <p>{process.env.REACT_APP_EMAIL_ADDRESS}</p>
                                        </div>
                                    </div>
                                    <div className="contact-info-box">
                                        <i className="fa fa-share-alt big-icon"></i>
                                        <div className="contact-info-box-content">
                                            <h4>Social Profiles</h4>
                                            <div className="social-contact">
                                                <ul>
                                                    <li className="facebook"><a href="#" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                                    <li className="twitter"><a href="#" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                                    <li className="google-plus"><a href="#" target="_blank"><i className="fa fa-google-plus"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </MainLayout>
    )
}