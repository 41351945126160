import React, { useCallback, useEffect, useState } from "react"
import Moment from 'react-moment';
import { ProtectdPageWrapper } from "../_wrapper"
import { ThunkConfig } from "../../redux/config";
import { Utils } from "../../core/utils";

export const ClientReferrals = () => {

    const [userDetail, setUserDetails] = useState<any>(JSON.parse(localStorage.getItem("userDetails")))
    const [referral, setReferral] = useState<any>(JSON.parse(localStorage.getItem("userReferral")))
    const [referralList, setReferralList] = useState<any[]>(JSON.parse(localStorage.getItem("userReferrals")))

    const GetUserReferralList = useCallback(async () => {
        setUserDetails(JSON.parse(localStorage.getItem("userDetails")))
        const userReferral: any[] = await ThunkConfig.HTTPGetRequest(`ReferralBonus/get-user-referral-report`);
        if (userReferral) {
            setReferral(userReferral)
        }
        const userReferrals: any[] = await ThunkConfig.HTTPGetRequest(`ReferralBonus/get-user-bonus`);
        if (userReferrals.length > 0) {
            localStorage.setItem("userReferrals", JSON.stringify(userReferrals))
            setReferralList(userReferrals)
        }

    }, [])

    useEffect(() => {
        GetUserReferralList();
    }, [GetUserReferralList, setReferralList, referralList, setReferral, referral])

    return (
        <ProtectdPageWrapper title="Referrals">
            <section className="shop-cart" style={{ margin: 0 }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="box p-3 bg-success alert alert-success text-white  shadow">
                                <div className="text-center">
                                    <h1 className="countnm font-size-50 m-0">{referral?.totalReferrals}</h1>
                                    <div className="text-uppercase">Total Referral(s)</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box p-3 bg-info alert alert-primary text-white  shadow">
                                <div className="text-center">
                                    <h1 className="countnm font-size-50 m-0">{referral?.referralBonus}</h1>
                                    <div className="text-uppercase">Referral Bonus</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box p-3 bg-primary text-white alert alert-danger shadow ">
                                <div className="text-center">
                                    <h1 className="countnm font-size-50 m-0">{Utils.Currency.format(referral ? referral.referralPercentage : 0)}</h1>
                                    <div className="text-uppercase">Referral Balance</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h6 className="m-5" style={{ display: 'flex', padding: '20px', fontSize: '22px', }}><div style={{ width: '180px' }}>Referral Link:</div> <div style={{ cursor: 'pointer', border: 'none', color: 'blue', width: '100%' }} onClick={() => Utils.CopyToClipboard(`${window.location.host}/account/signup?sponsor=${userDetail?.userName}`)}>{`${window.location.host}/account/signup?sponsor=${userDetail?.userName}`}</div></h6>

                    <div className="col-xs-12 table-responsive">
                        <table className="table order text-center" >
                            <thead>
                                <tr>
                                    <th style={{ paddingLeft: 20 }}>S/N</th>
                                    <th>From</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    referralList?.map((item: any, index) => {
                                        console.log(`deposit-${index + 1}}: `, item)
                                        return (
                                            <tr key={index} style={{ color: 'black' }}>
                                                <td className="text-left" style={{ paddingLeft: 20 }}><span className="price">{index + 1}.</span></td>
                                                <td className="text-left"><h6 className="product">{item?.OwnerId}</h6></td>
                                                <td className="text-left"><span className="price" style={{ color: 'lightgreen' }}>{Utils.Currency.format(item.RefPercentage)}</span></td>
                                                <td className="text-left"><span className="price"><Moment fromNow>{item.ActionDate}</Moment> </span></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {!referralList || referralList?.length === 0 ? <h3 className="text-center" style={{ color: 'silver' }}>No referrals record found!</h3> : null}
                    </div>
                </div>
            </section>
        </ProtectdPageWrapper>
    )
}