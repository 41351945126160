import React, { useState, useRef, useCallback, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Calculate, InsertChart } from "@mui/icons-material";
import Moment from 'react-moment';
import { common } from '@mui/material/colors';
import { ThunkConfig } from '../../redux/config';
import CustomAlert from '../../shared/custom-alert';
import CustomDialog from '../../shared/custom-dialog';
import { Utils } from '../../core/utils';
import { ProtectdPageWrapper } from '../../pages/_wrapper';
import { Button } from '@mui/material';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export function CompoundInvestment() {

    const uncompoundedInvestment = localStorage.getItem("uncompoundedInvestment")
    const [uncompoundedInvestmentList, setUncompoundedInvestmentList] = useState<any[]>(uncompoundedInvestment ? JSON.parse(uncompoundedInvestment) : [])

    const GetUncompoundedInvestment = useCallback(async () => {
        const response = await ThunkConfig.HTTPGetRequest(`invests/get-uncompounded-investment`);
        if (response) {
            localStorage.setItem("uncompoundedInvestment", JSON.stringify(response))
            setUncompoundedInvestmentList(response)
        }
    }, [setUncompoundedInvestmentList])


    useEffect(() => {
        GetUncompoundedInvestment()
    }, [GetUncompoundedInvestment])

    const [messages, setMessages] = useState();
    const [isBusy, setBusy] = useState(false);

    const alertRef = useRef(null);
    const dialogRef = useRef(null);

    let count = 1;

    const OnCompoundInvestment = useCallback(async (id) => {

        try {
            setBusy(true);
            const response = await ThunkConfig.HTTPPostRequest(`invests/compounding?investmentId=${id}`, null);
            if (response && response?.statusCode === 200) {
                await GetUncompoundedInvestment()
            }
            setBusy(false);
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
            setBusy(false);
            Utils.AlertToast("Something went wrong, please try again.", "info", "Okay!", true)
        }
    }, [GetUncompoundedInvestment])

    const OnReInvest = useCallback(async (id) => {
        setBusy(true);
        try {
            setBusy(true);
            const response = await ThunkConfig.HTTPPostRequest(`invests/re-invest?investmentId=${id}`, null);
            if (response && response?.statusCode === 200) {
                await GetUncompoundedInvestment()
            }
            setBusy(false);
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
            setBusy(false);
            Utils.AlertToast("Something went wrong, please try again.", "info", "Okay!", true)
        }
    }, [GetUncompoundedInvestment])

    return (
        <ProtectdPageWrapper title='Compound Investment'>

            <section className="shop-cart" style={{ margin: 0 }}>
                <div className="container">
                    <div className="col-xs-12 table-responsive">
                        <table className="table order text-center" >
                            <thead>
                                <tr>
                                    <th style={{ paddingLeft: 20 }}>S/N</th>
                                    <th>User Name</th>
                                    <th>Plan</th>
                                    <th>Amount</th>
                                    <th>Profits</th>
                                    <th colSpan={2}>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    uncompoundedInvestmentList?.map((item: any, index) => {
                                        return (
                                            <tr key={index} style={{ color: 'black' }}>
                                                <td className="text-left" style={{ paddingLeft: 20 }}><span className="price">{index + 1}.</span></td>
                                                <td className="text-left"><span className="price">{item?.UserId}</span></td>
                                                <td className="text-left"><span className="price">{item?.PlanId}</span></td>
                                                <td className="text-left"><span className="price">{Utils.Currency.format(item.AmountToInvest)}</span></td>
                                                <td className="text-left"><span className="price">{Utils.Currency.format(item.Profits)}</span></td>
                                                <td className="text-left"><span className="price"><Moment fromNow>{item?.ActionDate}</Moment></span></td>
                                                <td className="text-left">
                                                    {
                                                        !item.IsCompounded ?
                                                            <Button
                                                                color="primary"
                                                                onClick={() => OnCompoundInvestment(item.Id)}
                                                                className="pull-right"
                                                                disabled={isBusy}
                                                                startIcon={<Calculate />}
                                                                variant="contained"
                                                                sx={{ my: 2, float: 'right', px: 5, py: 1 }}
                                                            >
                                                                <span>Set</span>
                                                            </Button>
                                                            :
                                                            <Button
                                                                color="success"
                                                                onClick={() => OnReInvest(item.Id)}
                                                                className="pull-right"
                                                                disabled={isBusy}
                                                                startIcon={<InsertChart />}
                                                                variant="contained"
                                                                sx={{ my: 2, float: 'right', px: 5, py: 1 }}
                                                            >
                                                                <span>Reinvest</span>
                                                            </Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {uncompoundedInvestmentList.length === 0 && <h3 className="text-center" style={{ color: 'silver' }}>No investment to compound yet</h3>}
                    </div>
                </div>
            </section>
        </ProtectdPageWrapper >
    );
}