import React, { ReactNode, forwardRef, useImperativeHandle, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

type CustomDialogProps = {
  children: ReactNode
}

const CustomDialog = forwardRef(({ children }: CustomDialogProps, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleDialog() { setOpen(!open); }
  }));

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className='border-bottom'>Info</DialogTitle>
      <DialogContent>
        <div className='m-4'>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
});

export default CustomDialog;