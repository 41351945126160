import React from "react"
import { ReactNode } from "react"
import { MainLayout } from "../shared/main-layout"

type ProtectdPageWrapperProps = {
    title: string,
    children: ReactNode
}

export const ProtectdPageWrapper = ({ title, children }: ProtectdPageWrapperProps) => {
    return (
        <MainLayout>
            <div className="call-action-all">
                <div className="call-action-all-overlay">
                    <div className="text-center">
                        <div className="col-xs-12">
                            <h2 className="title-head"> <span>{title}</span></h2>
                        </div>
                    </div>
                    {children}
                </div>
            </div>

        </MainLayout>
    )
}