import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as pages from "../pages/_index"
import { LandingPage } from '../pages/landing-page';
import { ProtectedRoutes, AuthenticationRoutes, AdminRoutes } from "./protected-route";

export default function AppRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="investment-plans" element={<pages.InvestmentPlans />} />
        <Route path="about" element={<pages.AboutUs />} />
        <Route path="contact" element={<pages.Contact />} />

        <Route element={<AuthenticationRoutes />}>
          <Route path="account/login" element={<pages.Login />} />
          <Route path="account/register" element={<pages.Signup />} />
        </Route>

        <Route element={<ProtectedRoutes />}>
          <Route path="dashboard" element={<pages.Dashboard />} />
          <Route path="dashboard/user-profile" element={<pages.UserProfile />} />
          <Route path="dashboard/account-settings" element={<pages.AccountSettings />} />
          <Route path="dashboard/investment-plans" element={<pages.ClientInvestmentPlans />} />
          <Route path="dashboard/deposit" element={<pages.ClientDeposits />} />
          <Route path="dashboard/withdraws" element={<pages.ClientWithdraws />} />
          <Route path="dashboard/earnings" element={<pages.ClientEarnings />} />
          <Route path="dashboard/referrals" element={<pages.ClientReferrals />} />
        </Route>

        <Route element={<AdminRoutes />}>
          <Route path="dashboard/basic-settings" element={<pages.BasicSettings />} />
          <Route path="dashboard/plans-settings" element={<pages.PlansSettings />} />
          <Route path="dashboard/coin-settings" element={<pages.CoinSettings />} />
          <Route path="dashboard/compounding" element={<pages.Compounding />} />
          <Route path="dashboard/manage-users" element={<pages.ManageUsers />} />
          <Route path="dashboard/manage-deposits" element={<pages.ManageDeposits />} />
          <Route path="dashboard/manage-withdrawals" element={<pages.ManageWithdrawal />} />
          <Route path="dashboard/transaction-history" element={<pages.TransactionHistory />} />
          <Route path="dashboard/compound-investment" element={<pages.CompoundInvestment />} />
          <Route path="dashboard/mailing-system" element={<pages.MailingSystem />} />
        </Route>
        <Route path="*" element={<pages.PageNotFound />} />
      </Routes>
    </BrowserRouter>
  )
}