import React, { useState, useRef, useCallback, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { TextField } from '@mui/material';
import { Edit, Delete } from "@mui/icons-material";
import { ThunkConfig } from '../../redux/config';
import CustomAlert from '../../shared/custom-alert';
import CustomDialog from '../../shared/custom-dialog';
import CustomModal from '../../shared/custom-modal';
import { Utils } from '../../core/utils';
import { ProtectdPageWrapper } from '../../pages/_wrapper';

export function CoinSettings() {

    const coinSettings = localStorage.getItem("coinSettings")
    const [coinSettingsList, setCoinSettingsList] = useState<any[]>(coinSettings ? JSON.parse(coinSettings) : [])

    const [messages, setMessages] = useState<any>();
    const [isBusy, setBusy] = useState(false);
    const [values, setValues] = useState<any>();

    const alertRef = useRef(null);
    const dialogRef = useRef(null);
    const modalRef = useRef(null);

    const OnOpenModal = () => {
        setValues(null);
        modalRef.current.toggleModal();
    }

    const OnEditCoin = (item) => {
        setValues(item);
        modalRef.current.toggleModal();
    }

    const GetCoinSettings = useCallback(async () => {
        const response = await ThunkConfig.HTTPGetRequest(`coinsettings/get-all`);
        if (response) {
            localStorage.setItem("coinSettings", JSON.stringify(response))
            setCoinSettingsList(response)
        }
    }, [])

    const OnDeleteRequest = (item, address) => {
        setBusy(true);
        let newMessage = (<><b className='mb-4'>{address}, will be deleted.</b> <br /> <em className='text-danger'>Are you sure?</em></>);

        setMessages(newMessage);
        setValues(item);
        setBusy(false);
        dialogRef.current.toggleDialog();
    }

    const OnDelete = async () => {
        try {
            setBusy(true);
            const response = await ThunkConfig.HTTPPostRequest(`coinsettings/delete?id=${values?.id}`, null);
            if (response && response?.statusCode === 200) {
                await GetCoinSettings();
            }
            dialogRef.current.toggleDialog();
            setBusy(false);
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
            setBusy(false);
        }
    }

    const OnToggle = useCallback(async (id: string) => {
        try {
            setBusy(true);
            //
            const response = await ThunkConfig.HTTPPostRequest(`coinsettings/toggle?id=${id}`, null);
            if (response) {
                await GetCoinSettings();
            }
            setBusy(false);
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
            setBusy(false);
        }
    }, [GetCoinSettings])

    const SaveData = useCallback(async () => {
        try {
            setBusy(true);
            const response = await ThunkConfig.HTTPPostRequest(`coinsettings/form-data`, values);
            if (response && response?.statusCode === 200) {
                await GetCoinSettings();
                setValues(null);
                modalRef.current.toggleModal();
            }
            setBusy(false);
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
        }
    }, [values, GetCoinSettings])

    useEffect(() => {
        GetCoinSettings();
    }, [GetCoinSettings])


    return (
        <ProtectdPageWrapper title='Manage Coin Settings'>

            <section className="shop-cart" style={{ margin: 0 }}>
                <div className="container">
                    <div className='row mb-5'>
                        <div className='col-md-6'><h2>Manage coin</h2></div>
                        <div className='col-md-6 pull-right'><button disabled={isBusy} className='btn btn-success pull-right' style={{ width: 100 }} onClick={OnOpenModal}>New Coin</button></div>
                    </div>
                    <div className="col-xs-12 table-responsive">
                        <table className="table order text-center" >
                            <thead>
                                <tr>
                                    <th style={{ paddingLeft: 20 }}>S/N</th>
                                    <th>Address</th>
                                    <th>ApiKey</th>
                                    <th>Status</th>
                                    <th colSpan={2}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    coinSettingsList?.map((item: any, index) => {
                                        return (
                                            <tr key={index} style={{ color: 'black' }}>
                                                <td className="text-left" style={{ paddingLeft: 20 }}><span className="price">{index + 1}.</span></td>
                                                <td className="text-left"><h6 className="product">{item?.address}</h6></td>
                                                <td className="text-left"><span className="price">{item?.apiKey}</span></td>
                                                <td className="text-left">
                                                    <FormControlLabel control={<Switch color='success' disabled={isBusy} checked={item.isActive} onChange={() => OnToggle(item.id)} />} label={item.isActive ? "Active" : "Disabled"} />
                                                </td>
                                                <td className="text-left">
                                                    <Edit style={{ width: 30, height: 30, cursor: 'pointer' }} className='mr-4' color="primary" onClick={() => OnEditCoin(item)} />
                                                </td>
                                                <td className="text-left">
                                                    <Delete style={{ width: 30, height: 30, cursor: 'pointer' }} color="error" onClick={() => OnDeleteRequest(item, item.address)} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {coinSettingsList.length === 0 && <h3 className="text-center" style={{ color: 'silver' }}>No coin has been setup yet</h3>}
                    </div>
                </div>
            </section>

            <CustomModal ref={modalRef} width={'600px'} height={'200px'} title={'Coin Settings'} isBusy={false}>

                <div className="row p-3 d-flex align-content-center align-items-center justify-content-between">

                    <div className="col-md-12">
                        <TextField value={values?.address} onChange={(e) => { setValues({ ...values, address: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" label="Address" variant="standard" />
                    </div>

                    <div className="col-md-12">
                        <TextField value={values?.apiKey} onChange={(e) => { setValues({ ...values, apiKey: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" label="ApiKey" variant="standard" />
                    </div>

                    <div className="col-md-12" style={{ marginTop: 20 }}>
                        <button disabled={isBusy} onClick={() => SaveData()} type="submit" className="btn btn-primary btn-order pull-right">{isBusy ? "Saving..." : "Save"}</button>
                    </div>

                </div>

            </CustomModal>

            <CustomDialog ref={dialogRef} >
                <h4 className='text-success text-center mb-5 mt-3'>{messages}</h4>
                <div className='row'>
                    <div className='col-md-6'><button disabled={isBusy} className='btn btn-default' onClick={() => dialogRef.current.toggleDialog()}>No</button></div>
                    <div className='col-md-6 text-right'>
                        <button disabled={isBusy} onClick={() => OnDelete()} type="submit" className="btn btn-primary btn-order pull-right">{isBusy ? "Deleting..." : "Yes"}</button>
                    </div>
                </div>
            </CustomDialog>

            <CustomAlert ref={alertRef}><h3 className='text-center'> {messages}</h3></CustomAlert>

        </ProtectdPageWrapper>
    );
}