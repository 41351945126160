import { createSlice } from '@reduxjs/toolkit';
import { AuthThunk } from '../thunk/auth.thunk';
import { LoginModel, UserDetails } from '../../dtos/_index';

type LoginData = typeof LoginModel;
type UserData = typeof UserDetails;

interface AuthState {
    isBusy: boolean;
    status: 'idle' | 'pending' | 'fulfilled' | 'rejected';
    loginModel: LoginData;
    userDetails: UserData
}

const initialState: AuthState = {
    isBusy: false,
    status: 'idle',
    loginModel: LoginModel,
    userDetails: UserDetails
};

type StateUpdater = (state: AuthState, data: any) => void;

const handleAsyncAction = (builder: any, thunkAction: any, stateUpdate: StateUpdater) => {
    builder
        .addCase(thunkAction.pending, (state: AuthState) => {
            state.isBusy = true;
            state.status = 'pending';
        })
        .addCase(thunkAction.fulfilled, (state: AuthState, action: any) => {
            state.isBusy = false;
            state.status = 'fulfilled';
            stateUpdate(state, action.payload?.data);
        })
        .addCase(thunkAction.rejected, (state: AuthState) => {
            state.isBusy = false;
            state.status = 'rejected';
        });
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        handleAsyncAction(builder, AuthThunk.Login, (state, data: any) => {
            localStorage.setItem("accessToken", data?.token)
            state.userDetails = data;
        });
    },
});

export class AuthStates {
    static getUserDetails = (state: any) => state.auth.userDetails as UserData;
}

export default authSlice.reducer