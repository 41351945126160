import React, { useCallback, useEffect } from "react"
import { MainLayout } from "../shared/main-layout"
import DashboardDataReportAdmin from "./dashboard-data-report-admin"
import DashboardDataReportUser from "./dashboard-data-report-user"
import { Utils } from "../core/utils"
import { ThunkConfig } from "../redux/config"

export const Dashboard = () => {

    const role = localStorage.getItem("role")
    const userRole = Utils.DecryptData(role)

    const user = JSON.parse(localStorage.getItem("userDetails")) as unknown as any

    const GetUserWallet = useCallback(async () => {
        const walletDetails = await ThunkConfig.HTTPGetRequest(`wallet/get-user-wallet-detail?userId=${user?.id}`);
        if (walletDetails) {
            localStorage.setItem("userWalletDetails", JSON.stringify(walletDetails))
        }

        const walletAddress = await ThunkConfig.HTTPGetRequest(`WalletAddress/get-selected-user-wallet-address?userId=${user?.id}`);
        if (walletAddress) {
            localStorage.setItem("userWalletAddress", JSON.stringify(walletAddress))
        }
    }, [user])

    useEffect(() => {
        GetUserWallet()
    }, [GetUserWallet])

    return (
        <MainLayout>
            <div style={{ padding: 0, margin: 30 }}>
                {
                    localStorage.getItem("accessToken") && userRole?.toLowerCase() === "admin" ?
                        <DashboardDataReportAdmin />
                        :
                        <DashboardDataReportUser />
                }
            </div>
        </MainLayout>
    )
}