/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { FormEvent, useCallback, useMemo, useState } from "react"
import { LoginModel } from "../dtos/_index";
import { Utils } from "../core/utils";
import { IndexedDB } from "../indexedDB/db-services";
import { ThunkConfig } from "../redux/config";

export const LoginServices = () => {

    const navigate = useNavigate();

    const [values, setValues] = useState(LoginModel);
    const [isBusy, setIsBusy] = useState(false);

    const HandleLogin = useCallback(async (event: FormEvent) => {
        event.preventDefault();
        try {
            setIsBusy(true);


            const response = await ThunkConfig.HTTPPostRequest("account/login", values);

            if (response.statusCode === 200) {

                // await IndexedDB.AddData(IndexedDB.StoreNames.UserDetails, response?.data, IndexedDB.StoreKeys.UserDetails)
                localStorage.setItem("accessToken", response?.data?.token)
                localStorage.setItem("role", Utils.EncryptData(response?.data?.role))
                localStorage.setItem("userDetails", JSON.stringify(response?.data))
                // localStorage.setItem("sidebarStatus", "Visible");

                if (response.data.role !== "Admin") {
                    const userDataReport = await ThunkConfig.HTTPGetRequest("DashboardDataReport/get-user-data-report");
                    if (userDataReport) {
                        localStorage.setItem("dataReport", JSON.stringify(userDataReport))
                        // await IndexedDB.AddData(IndexedDB.StoreNames.dashboardData, userDataReport, IndexedDB.StoreKeys.dashboardData)
                    }
                } else {
                    const adminDataReport = await ThunkConfig.HTTPGetRequest("DashboardDataReport/data-report");
                    if (adminDataReport) {
                        localStorage.setItem("dataReport", JSON.stringify(adminDataReport))
                        // await IndexedDB.AddData(IndexedDB.StoreNames.dashboardData, adminDataReport, IndexedDB.StoreKeys.dashboardData)
                    }
                }

                const walletDetails = await ThunkConfig.HTTPGetRequest(`wallet/get-user-wallet`);
                if (walletDetails) {
                    localStorage.setItem("walletDetails", JSON.stringify(walletDetails))
                }

                setIsBusy(false);

                return navigate("/dashboard")
            } else {
                setIsBusy(false);
                Utils.AlertToast(response?.message, "error", "Okay!", true)
            }
        } catch (error) {
            setIsBusy(false);
            console.log("login-error: ", error)
            Utils.AlertToast("Something went wrong, please try again.", "error", "Ok", true)
        }
    }, [values])

    const HandleLogout = useCallback(async () => {
        try {
            localStorage.clear()
            window.location.href = "/"
            // await IndexedDB.DeleteAll()
        } catch (error) {
        }
    }, [])

    const LoginProps = {
        values, setValues,
        isBusy, setIsBusy,
        HandleLogin,
        HandleLogout
    };


    return useMemo(() => ({ ...LoginProps }), [LoginProps]);
}