import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRoute from "./shared/AppRoute";
import { Provider } from 'react-redux';
import { store } from './redux/store';
import ErrorBoundary from './shared/error-boundary';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

const root = ReactDOM.createRoot(
  document.getElementById('base_content') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <AppRoute />
        </I18nextProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);