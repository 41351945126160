import React, { useState, useEffect, useCallback } from 'react';
import { Utils } from "../core/utils";
import { ThunkConfig } from '../redux/config';

export default function DashboardDataReportUser() {

    const [walletDetails, setWalletDetails] = useState<any>(JSON.parse(localStorage.getItem("walletDetails")))

    const GetUserWithdrawals = useCallback(async () => {

        const walletDetails = await ThunkConfig.HTTPGetRequest(`wallet/get-user-wallet`);
        console.log("walletDetails: ", walletDetails)
        if (walletDetails) {
            localStorage.setItem("walletDetails", JSON.stringify(walletDetails))
            setWalletDetails(walletDetails)
        }
    }, [setWalletDetails])

    useEffect(() => {
        GetUserWithdrawals();
    }, [GetUserWithdrawals])

    return (
        <div className='row'>
            <div className="col-md-4">
                <div className="box p-3 bg-success alert alert-success text-white  shadow">
                    <div className="text-center">
                        <h1 className="countnm font-size-50 m-0">{Utils.Currency.format(walletDetails ? walletDetails.AvailableBalance : 0)}</h1>
                        <div className="text-uppercase">Available Balance</div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="box p-3 bg-info alert alert-primary text-white  shadow">
                    <div className="text-center">
                        <h1 className="countnm font-size-50 m-0">{Utils.Currency.format(walletDetails ? walletDetails.ReferralBonus : 0)}</h1>
                        <div className="text-uppercase">Referral Bonus</div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="box p-3 bg-primary text-white alert alert-danger shadow ">
                    <div className="text-center">
                        <h1 className="countnm font-size-50 m-0">{Utils.Currency.format(walletDetails ? walletDetails.Profits : 0)}</h1>
                        <div className="text-uppercase">Profits</div>
                    </div>
                </div>
            </div>

            {/* <div className="col-md-6">
                <div className="dashboard-card">
                    <h3>All Balance</h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">
                            <h4 className="text-success">{Utils.Currency.format(dataReport?.allBalance > 0 ? dataReport?.allBalance : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6">
                <div className="dashboard-card">
                    <h3>All Active Deposit</h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.allActiveDeposit > 0 ? dataReport?.allActiveDeposit : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6">
                <div className="dashboard-card">
                    <h3>All Deposit</h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.allDeposit > 0 ? dataReport?.allDeposit : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>Deposits</h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.Deposits > 0 ? dataReport?.Deposits : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>WITHDRAWALS</h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.withdrawals > 0 ? dataReport?.withdrawals : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        ACTIVE INVESTMENTS
                    </h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.activeInvestments > 0 ? dataReport?.activeInvestments : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>COMPLETED INVESTMENTS</h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.completedInvestments > 0 ? dataReport?.completedInvestments : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '100%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        PENDING DEPOSITS
                    </h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.pendingDeposits > 0 ? dataReport?.pendingDeposits : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        CONFIRMED DEPOSITS
                    </h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.confirmedDeposits > 0 ? dataReport?.confirmedDeposits : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        PENDING WITHDRAWALS
                    </h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.pendingWithdrawals > 0 ? dataReport?.pendingWithdrawals : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        COMPLETED WITHDRAWALS
                    </h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.completedWithdrawals > 0 ? dataReport?.completedWithdrawals : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div> */}

            <iframe title="update" height="100vh" src="https://widget.coinlib.io/widget?type=full_v2&amp;theme=dark&amp;cnt=10&amp;pref_coin_id=1505&amp;graph=yes"
                style={{ marginTop: 20, overflow: 'visible', backgroundColor: 'transparent', width: "100%", height: "100vh", border: 0, margin: 0, padding: 0 }}>  </iframe>

        </div>
    )
}