import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkConfig } from "../config";


export class AuthThunk {

    static Login = createAsyncThunk("account/login", async (payload: any, { rejectWithValue }): Promise<any> => {
        try {
            const response = await ThunkConfig.HTTPPostRequest("account/login", payload);
            console.log("login-response: ", response)
            return response
        } catch (error: any) {
            return rejectWithValue({ status: 400, message: "Something went wrong, please try again" }) as any;
        }
    });

    static Logout = createAsyncThunk("account/logout", async (payload, { rejectWithValue }): Promise<any> => {
        try {
            return await ThunkConfig.HTTPPostRequest("account/logout", null);
        } catch (error: any) {
            return rejectWithValue({ status: 400, message: error?.message || "Something went wrong, please try again" }) as any;
        }
    });

    static Register = createAsyncThunk("account/register", async (payload: any, { rejectWithValue }): Promise<any> => {
        try {
            return await ThunkConfig.HTTPPostRequest("account/register", payload);
        } catch (error: any) {
            return rejectWithValue({ status: 400, message: "Something went wrong, please try again" }) as any;
        }
    });
}