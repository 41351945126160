
import React, { ReactNode, forwardRef, useImperativeHandle, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Cancel } from '@mui/icons-material';
import zIndex from '@mui/material/styles/zIndex';

type CustomModalProps = {
    children: ReactNode,
    height?: string,
    width?: string,
    title: string,
    isBusy: boolean
}

const CustomModal = forwardRef(({ children, height, width, title, isBusy }: CustomModalProps, ref) => {

    const style = {
        position: 'relative',
        display: "table",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        maxHeight: '80vh',
        minWidth: '350px',
        height: height,
        overflow: 'scroll',
        borderRadius: "10px",
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        p: 5,
        pb: 2,
        zIndex:1
    };

    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        toggleModal() { setOpen((prev) => !prev); }
    }));

    return (
        <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" style={{zIndex:999}}>
            <Box sx={style}>

                <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between', justifyItems:"stretch"}}>
                    <h4 style={{ marginTop: '10px' }}>{title}</h4>
                    <button disabled={isBusy} onClick={() => setOpen((prev) => !prev)} style={{ color: 'red', cursor: 'pointer', fontSize: 18,  border: 'none', backgroundColor: 'white' }}><Cancel color="error" style={{ width: 30, height: 30 }} /></button>
                </div>

                <div style={{ maxHeight: '80vh', overflowY: 'auto', overflowX: 'hidden', padding: 0, paddingTop: 30, paddingBottom: 30, margin: 0 }}>
                    {children}
                </div>
            </Box>
        </Modal>
    );
});

export default CustomModal;