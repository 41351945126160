import React, { FormEvent, useCallback, useEffect, useState } from "react"
import { ProtectdPageWrapper } from "./_wrapper";
import { Utils } from "../core/utils";
import { ThunkConfig } from "../redux/config";
import { Link } from "react-router-dom";

const WalletModel = { Coin: '', Address: '', UserId: '' }
const ManageWalletModel = { WalletType: '', FundType: '', SendFundTo: '', Amount: 0, UserName: '', UserId: '' }

export const UserProfile = () => {

    const role = localStorage.getItem("role")
    const userRole = Utils.DecryptData(role)
    const [userDetails, setUserDetails] = useState<any>(JSON.parse(localStorage.getItem("userDetails")))
    const [walletAddressList, setWalletAddressList] = useState<any[]>(localStorage.getItem("userWalletAddress") ? JSON.parse(localStorage.getItem("userWalletAddress")) : [])
    const [walletAddress, setWalletAddress] = useState(WalletModel)
    const [manageWallet, setManageWallet] = useState(ManageWalletModel);

    const [values, setValues] = useState<any>(userDetails)

    useEffect(() =>{
        setWalletAddressList(localStorage.getItem("userWalletAddress") ? JSON.parse(localStorage.getItem("userWalletAddress")) : [])
    }, [walletAddressList])

    async function GetWalletAddress() {
        try {
            const walletAddressLists = await ThunkConfig.HTTPGetRequest(`WalletAddress/get-user-wallet-address?userId=${userDetails?.id}`);
            setWalletAddressList(walletAddressList);
            localStorage.setItem("userWalletAddress", JSON.stringify(walletAddressLists));
        } catch (error) {

        }
    }

    async function SaveWalletAddress(event: FormEvent) {
        event.preventDefault();
        setWalletAddress(prev => ({ ...prev, UserId: userDetails?.userId }))
        try {
            if (!walletAddress.Address || !walletAddress.Coin) {
                Utils.AlertToast("Please fill in the required filed", "error", "Okay!", true)
                return;
            }
            const response = await ThunkConfig.HTTPPostRequest(`WalletAddress/save-data`, { ...walletAddress, userId: userDetails?.id });
            if (response && response?.statusCode === 200) {
                setWalletAddress(WalletModel)
            }
            await GetWalletAddress();
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
            Utils.AlertToast("Something went wrong, please try again!", "error", "Okay!", true)
        }

    }

    async function OnDeleteWallet(id: string) {
        try {
            const response = await ThunkConfig.HTTPPostRequest(`WalletAddress/delete-wallet-address?id=${id}`, null);
            if (response.statusCode === 200) {
                Utils.AlertToast(response?.message, "error", "Okay!", true)
                await GetWalletAddress();
            }
        } catch (error) {
        }
    }

    async function OnFundWallet(event: FormEvent) {
        event.preventDefault();
        manageWallet.UserId = userDetails?.userName;
        manageWallet.UserName = userDetails?.userName;
        const response = await ThunkConfig.HTTPPostRequest(`wallet/manage-fund-wallet`, manageWallet);
        if (response && response.statusCode === 200) {
            await GetWalletAddress();
        }
        setManageWallet(ManageWalletModel)
    }

    const UpdateProfile = useCallback(async (event: FormEvent) => {
        event.preventDefault();

        console.log("values: ", values)

        const response = await ThunkConfig.HTTPPostRequest("account/update-profile", values);
        if (response && response?.statusCode === 200) {
            localStorage.clear()
            window.location.href = "/account/login"
        }
        Utils.AlertToast(response?.message, "info", "Okay!", true)
    }, [values])

    useEffect(() => {
        setUserDetails(JSON.parse(localStorage.getItem("userDetails")));
        setWalletAddressList(localStorage.getItem("userWalletAddress") ? JSON.parse(localStorage.getItem("userWalletAddress")) : [])
        GetWalletAddress();
    }, [])


    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    function handleFileChange (event) {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            Utils.AlertToast("Please select an image file.", "info", "Okay", true)
            event.target.value = null;
            return;
        }
    };

    const [isBusy, setIsBusy] = useState(false);

    const HandleUpload = async () => {
        try {
            if (!selectedFile) {
                Utils.AlertToast("Please select a file before uploading.", "info", "Okay", true)
                return;
            }
            setIsBusy(true)
            await Utils.TaskDelay()

            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('userId', userDetails?.userId);

            const response = await ThunkConfig.HTTPPostRequest("account/upload-file", formData);
            if (response && response.statusCode === 200) {
                const _userData = { ...userDetails, profileImage: response?.data };
                setUserDetails(_userData)
                localStorage.setItem("userDetails", JSON.stringify(_userData))
                setPreviewUrl(null)
                setSelectedFile(null)
            }

            setIsBusy(false)
            Utils.AlertToast(response?.message, "info", "Okay", true)
        } catch (error) {
            Utils.AlertToast("Something went wrong, please try again.", "info", "Okay", true)
            setIsBusy(false)
        }
    };

    return (
        <ProtectdPageWrapper title="Profile Information">
            <div className="container">
                <div className="row" style={{ padding: '5%', paddingTop: '120px', paddingBottom: '60px', marginBottom: '120px', borderRadius: '20px', backgroundColor: 'rgba(255,255,255,0.1)' }}>
                    <div className="col-md-4" style={{ marginBottom: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <label htmlFor="upload-file">
                            <img src={previewUrl ? previewUrl : userDetails?.profileImage ? `${process.env.REACT_APP_SERVER_BASEURL}Passports/${userDetails?.profileImage}` : "/no-user-image.jpg"} alt="no passport" width={200} height={200} style={{ height: "200px", width: "200px", borderRadius: 10, boxShadow: '0 0 5px 5px gray' }} />
                        </label>
                        <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {!isBusy && <label htmlFor="upload-file" style={{ color: 'white', fontWeight: 'bolder' }}>Select passport</label>}
                            {
                                previewUrl && <button disabled={isBusy} className="btn btn-info btn-order" style={{ marginLeft: '30px' }} onClick={() => HandleUpload()}>{isBusy ? "Saving..." : "Save"}</button>
                            }
                            <input id="upload-file" style={{ display: 'none' }} type="file" accept="image/*" onChange={handleFileChange} />
                        </div>
                    </div>

                    <div className="col-md-8" style={{ marginBottom: '40px' }}>
                        <div className="col-xs-12 col-md-6 form-group">
                            <label style={{ color: 'silver' }}><span style={{ color: 'gray', marginRight: 5 }}>Name:</span> {values?.name}</label>
                        </div>

                        <div className="col-xs-12 col-md-6 form-group">
                            <label style={{ color: 'silver' }}><span style={{ color: 'gray', marginRight: 5 }}>Country:</span> {values?.country}</label>
                        </div>

                        <div className="col-xs-12 col-md-6 form-group">
                            <label style={{ color: 'silver' }}><span style={{ color: 'gray', marginRight: 5 }}>User Name:</span> {values?.userName}</label>
                        </div>

                        <div className="col-xs-12 col-md-6 form-group">
                            <label style={{ color: 'silver' }}><span style={{ color: 'gray', marginRight: 5 }}>Email:</span> {values?.email}</label>
                        </div>

                        <div className="col-xs-12 col-md-6 form-group">
                            <Link to="/dashboard/account-settings" className="btn btn-info btn-order">Edit Profile</Link>
                        </div>
                    </div>

                    <div className="row" style={{ marginBottom: '20px' }}>
                        <div className="col-md-12" style={{ marginTop: 20 }}>
                            <div className="form-group">
                                <label>WALLET ADDRESS</label>
                                <hr style={{ margin: 0, padding: 0 }} />
                            </div>
                        </div>

                        <div className="col-lg-12" style={{ margin: 0, padding: 0 }}>
                            <form className="row" onSubmit={(event) => SaveWalletAddress(event)}>
                                <div className="col-xs-12 col-md-4">
                                    <div className="form-group">
                                        <select
                                            onChange={(e) => { setWalletAddress((prev) => ({ ...prev, Coin: e.target.value })) }}
                                            value={walletAddress?.Coin}
                                            required
                                            className="form-control" style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                                            <option selected disabled value="" style={{ color: 'silver' }}>-- select wallet type --</option>
                                            <option value="Bitcoin">Bitcoin</option>
                                            <option value="Ethereum">Ethereum</option>
                                            <option value="Tether USDT">Tether USDT</option>
                                            <option value="Binance">Binance</option>
                                            {/* <option value="Reinvest">Reinvest with balance</option> */}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-7">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            onChange={(e) => { setWalletAddress((prev) => ({ ...prev, Address: e.target.value })) }}
                                            value={walletAddress?.Address}
                                            placeholder="WALLET ADDRESS"
                                            className="form-control"
                                            style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}
                                            required />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-1">
                                    <button type="submit" className="btn btn-primary btn-order pull-right">Save</button>
                                </div>
                            </form>
                        </div>

                        <div className="col-md-12 table-responsive" style={{ marginTop: '30px' }}>
                            <table className="table order text-center" >
                                <thead>
                                    <tr>
                                        <th style={{ paddingLeft: 20 }}>S/N</th>
                                        <th>Wallet Type</th>
                                        <th>Address</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        walletAddressList?.map((item, index) => {
                                            console.log(`deposit-${index + 1}}: `, item)
                                            return (
                                                <tr key={index} style={{ color: 'black' }}>
                                                    <td className="text-left" style={{ paddingLeft: 20 }}><span className="price">{index + 1}.</span></td>
                                                    <td className="text-left"><span className="price">{item?.coin}</span></td>
                                                    <td className="text-left"><span className="price">{item?.address}</span></td>
                                                    <td className="text-left">
                                                        <label onClick={() => OnDeleteWallet(item?.id)} style={{ color: 'red', cursor: 'pointer' }}>Delete</label>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {!walletAddressList || walletAddressList?.length === 0 ? <h3 className="text-center" style={{ color: 'silver' }}>You've not added your wallet address!</h3> : null}
                        </div>
                    </div>

                </div>

                {localStorage.getItem("accessToken") && userRole?.toLowerCase() === "admin" ?

                    <form onSubmit={(event) => OnFundWallet(event)} className="row" style={{ marginTop: 80, marginBottom: 100 }}>
                        <div className="col-xs-12">
                            <div className="form-group">
                                <label>MANAGE FUNDS</label>
                                <hr style={{ margin: 0, padding: 0 }} />
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label active">Select Wallet:</label>
                                <div className="col-sm-8">
                                    <select value={manageWallet?.WalletType} onChange={(e) => setManageWallet({ ...manageWallet, WalletType: e.target.value })} className="form-control" style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                                        <option value="" selected disabled>Choose Option</option>
                                        <option value="Bitcoin">Bitcoin</option>
                                        <option value="Other Wallet">Other Wallet</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label active">Select Fund Type:</label>
                                <div className="col-sm-8">
                                    <select value={manageWallet?.FundType} onChange={(e) => setManageWallet({ ...manageWallet, FundType: e.target.value })} className="form-control" style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                                        <option value="" selected disabled>Choose Option</option>
                                        <option value="Add">Add</option>
                                        <option value="Add">Substract</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label active">Send fund to:</label>
                                <div className="col-sm-8">
                                    <select value={manageWallet?.SendFundTo} onChange={(e) => setManageWallet({ ...manageWallet, SendFundTo: e.target.value })} className="form-control" style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                                        <option value="" selected disabled>Choose Option</option>
                                        <option value="Balance">Balance</option>
                                        <option value="Profits">Profits</option>
                                        <option value="ReferralBonus">Referral Bonus</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label active">Amount: </label>
                                <div className="col-sm-8">
                                    <input value={manageWallet?.Amount} onChange={(e: any) => setManageWallet({ ...manageWallet, Amount: Number(e.target.value?.replaceAll(/[^0-9]/g, "")) })} className="form-control" type="search" placeholder="Enter Amount" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <button type="submit" className="btn btn-primary btn-order pull-right">Submit</button>
                        </div>

                    </form>
                    : null}
            </div>
        </ProtectdPageWrapper>
    )
}