import React, { FormEvent, useCallback, useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Utils } from "../../core/utils"
import { ThunkConfig } from "../../redux/config"
import CustomModal from "../../shared/custom-modal"

export const PlanList = () => {

    const navigate = useNavigate();
    const modalRef = useRef<any>();

    const existingPlanList = JSON.parse(localStorage.getItem("investmentPlans")) as unknown as any[]
    const [planList, setPlanList] = useState<any[]>(existingPlanList ? existingPlanList : [])
    const [fundWallet, setFundWallet] = useState({ PlanName: '', PlanId: '', Min: 0, Max: 0, Amount: 0, WalletType: '', TransactionId: '' });
    const [isModalOpen, setOpenModal] = useState(false);
    const [completedTransactionId, setCompletedTransactionId] = useState<string>();
    const [paymentDetail, setPaymentDetail] = useState<any>();
    const [showInvestmentWalletAddress, setShowInvestmentWalletAddress] = useState(false);
    const [message, setMessage] = useState<any>();

    const [isBusy, setIsBusy] = useState(false);
    const InvestmentPlans = useCallback(async () => {
        const plans = await ThunkConfig.HTTPGetRequest(`plans/get-all`);
        if (plans) {
            localStorage.setItem("investmentPlans", JSON.stringify(plans))
            setPlanList(plans)
        }
    }, [setPlanList])

    useEffect(() => {
        InvestmentPlans()
    }, [InvestmentPlans])

    //onClick={() => OnChoosePlan(plans.id, plans.minimum, plans.maximum)}
    const HandleNewInvestment = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, planName: any, id: any, minimum: number, maximum: number) => {
        event.preventDefault();

        setCompletedTransactionId("")

        if (!localStorage.getItem("accessToken"))
            return navigate("/account/login")

        setFundWallet({
            PlanName: planName,
            PlanId: id,
            Max: maximum,
            Min: minimum,
            Amount: 0,
            WalletType: '',
            TransactionId: ''
        });
        setOpenModal(!isModalOpen)
        if (modalRef.current) {
            modalRef.current.toggleModal()
        }
    }

    const InvestmentNow = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        if (fundWallet.Amount > fundWallet.Max) {
            modalRef.current.toggleModal()
            Utils.AlertToast(`The maximum investment amount allowed for ${fundWallet.PlanName} is ${Utils.Currency.format(fundWallet.Max)}`, "info", "Okay!", true)
        }
        else if (fundWallet.Amount < fundWallet.Min) {
            modalRef.current.toggleModal()
            Utils.AlertToast(`The minimum investment amount allowed for ${fundWallet.PlanName} is ${Utils.Currency.format(fundWallet.Min)}`, "info", "Okay!", true)
        }
        else {

            setIsBusy(true)

            const response = await ThunkConfig.HTTPPostRequest("invests/invest", fundWallet);

            if (response.statusCode === 200) {
                setPaymentDetail(response?.data)
                setShowInvestmentWalletAddress(true)
                const userInvestment: any[] = await ThunkConfig.HTTPGetRequest(`invests/get-user-investment`);
                if (userInvestment.length > 0) {
                    localStorage.setItem("userInvestment", JSON.stringify(userInvestment))
                }
            } else {
                modalRef.current.toggleModal()
                Utils.AlertToast(response?.message, "info", "Okay!", true)
            }
            setIsBusy(false)
        }
    }

    const ConfirmTransaction = async () => {
        setMessage("")
        if (completedTransactionId.length < 8) {
            setMessage("Please provide a valid transaction id")
            return;
        }

        setIsBusy(true);

        const response = await ThunkConfig.HTTPPostRequest("invests/confirm-transaction", { transactionId: paymentDetail?.transactionId, completedTransactionId });

        if (response.statusCode === 200) {
            setShowInvestmentWalletAddress(false)
            modalRef.current.toggleModal()
            const userInvestment: any[] = await ThunkConfig.HTTPGetRequest(`invests/get-user-investment`);
            if (userInvestment.length > 0) {
                localStorage.setItem("userInvestment", JSON.stringify(userInvestment))
            }
        }
        Utils.AlertToast(response?.message, "info", "Okay!", true)
        setIsBusy(false);
    }

    return (
        <section className="pricing">
            <div className="container">
                <div className="row text-center">
                    <h2 className="title-head">affordable <span>packages</span></h2>
                    <p style={{color:'silver', fontSize:22}}>Embrace the future you envision by investing in thematic strategies, providing diversified exposure to market segments that will shape our lives.</p>
                </div>

                <div className="row pricing-tables-content pricing-page">
                    <div className="pricing-container">
                        <ul className="pricing-list bounce-invert" style={{ justifyContent: 'center', alignItems: 'center' }}>
                            {
                                planList?.map((item, index) => {
                                    return (
                                        <li key={index} className="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                                            <ul className="pricing-wrapper">
                                                <li>
                                                    <header className="pricing-header">
                                                        <h2>{item.planName} <span className="text-white" style={{ fontSize: '12px' }}>Up to {item.percentage}% for {item.planName} starting at {Utils.Currency.format(item.minimum)}</span></h2>

                                                        <div className="price">
                                                            {/* <span className="currency"><i className="fa fa-dollar"></i></span> */}
                                                            <span className="value">{Utils.Currency.format(item.maximum)} <sub style={{ fontSize: 12, fontWeight: "normal" }}>max</sub></span>
                                                        </div>
                                                    </header>
                                                    <footer className="pricing-footer">
                                                        <Link onClick={(event) => HandleNewInvestment(event, item.planName, item?.id, item?.minimum, item?.maximum)} to={window.location.pathname} className="btn btn-primary">Invest NOW</Link>
                                                    </footer>
                                                </li>
                                            </ul>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>

            <CustomModal width={showInvestmentWalletAddress ? "60%" : "40%"} height="auto" title={`${fundWallet.PlanName} - MIN: ${Utils.Currency.format(fundWallet.Min)},  MAX: ${Utils.Currency.format(fundWallet.Max)}`} ref={modalRef} isBusy={false} >
                {/* <TextField value={amount} onChange={(e) => { setAmount(e.target.value) }} sx={{ width: '100%', my: 3 }} id="standard-basic" label="Amount to Fund" variant="standard" /> */}

                {!showInvestmentWalletAddress ?
                    <form onSubmit={(event) => InvestmentNow(event)}>
                        <div className="form-group">
                            <label>Amount</label>
                            <input
                                min={fundWallet.Min}
                                max={fundWallet.Max}
                                className="form-control"
                                style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}
                                onChange={(e: any) => { setFundWallet((prev) => ({ ...prev, Amount: Number(e.target.value.replaceAll(/[^0-9]/g, "")) })) }}
                                value={fundWallet.Amount}
                                placeholder="Amount"
                                type="search"
                                required />
                        </div>

                        <div className="form-group">
                            <label>Select payment method</label>
                            <select
                                onChange={(e) => { setFundWallet((prev) => ({ ...prev, WalletType: e.target.value })) }}
                                value={fundWallet.WalletType}
                                required
                                className="form-control"
                                style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                                <option selected disabled value=""> ---</option>
                                <option value="Bitcoin">Bitcoin</option>
                                <option value="Ethereum">Ethereum</option>
                                <option value="Tether USDT">Tether USDT</option>
                                <option value="Binance">Binance</option>
                                {/* <option value="Reinvest">Reinvest with balance</option> */}
                            </select>
                        </div>

                        <div className="row" style={{ marginTop: 40 }}>
                            <div className="col-md-8" style={{ display: 'flex', flexDirection: 'row' }}>
                                {/* <h5 className="mr-1 d-table" style={{ marginRight: 20 }}> Fund Wallet </h5>
                                <FormControlLabel control={<Switch checked={isBitcoinWallet} onChange={() => { setBitcoinWallet(!isBitcoinWallet); setOtherWallet(false); }} />} label="Bitcoin" />
                                <FormControlLabel control={<Switch checked={isOtherWallet} onChange={() => { setOtherWallet(!isOtherWallet); setBitcoinWallet(false) }} />} label="Other Wallet" /> */}
                            </div>

                            <div className="col-md-4 text-right">
                                <button type="submit" className="btn btn-primary" disabled={isBusy}>{isBusy ? "Please wait..." : "Proceed"}</button>
                                {/* <Link onClick={(event) => event.preventDefault()} to={window.location.pathname} className="btn btn-primary">Invest NOW</Link> */}
                            </div>
                        </div>

                    </form>
                    :
                    <div className="row p-3 d-flex align-content-center align-items-center justify-content-between bg-light">

                        <div className="col-md-5">
                            <div className="align-items-center justify-content-center">
                                <h5 style={{ width: '100%', position: 'relative', display: 'flex' }}>Reference:  <b className="ml-2" style={{ marginLeft: 5, color: 'black', fontWeight: 'bold' }}>{paymentDetail?.transactionId}</b></h5>
                                <img className="d-table" src="./qr-code.png" alt="wallet address" style={{ width: '180px', height: '180px' }} />
                                <div><em>Scan the qrcode</em></div>
                            </div>
                        </div>

                        <div className="col-md-7 bg-light">
                            <div className="d-table p-4 flex-wrap content-wrap">

                                <h2 className="m-5 text-success" style={{ color: 'darkgreen' }}><b>{paymentDetail?.amountInUSD}</b></h2>
                                <em className="text-center" style={{ color: 'black' }}>{paymentDetail?.message}</em>
                                <hr style={{ margin: 10, marginBottom: 10, marginTop: 5, padding: 0 }} />
                                <b onClick={() => Utils.CopyToClipboard(paymentDetail?.address)} style={{ cursor: 'pointer' }}><h4 className="text-danger">{paymentDetail?.address}</h4></b>
                                <hr />
                                <div className="form-group">
                                    <h2 style={{ marginBottom: 8 }}>Confirm Transaction</h2>
                                    <label>Enter transaction id or hash to complete the payment</label>
                                    <label>{fundWallet.TransactionId}</label>
                                    <input
                                        className="form-control"
                                        style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}
                                        onChange={(e) => { setCompletedTransactionId(e.target.value) }}
                                        value={completedTransactionId}
                                        placeholder="enter transaction id"
                                        type="text"
                                        required />
                                </div>

                                {message && <div style={{ marginTop: 10 }}> <p className="text-danger">{message}</p> </div>}

                                <div className="text-right">
                                    <button type="submit" onClick={() => ConfirmTransaction()} className="btn btn-primary" disabled={isBusy}>{isBusy ? "Please wait..." : "Proceed"}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                }
            </CustomModal>
        </section>
    )
}