import React from "react"
import { MainLayout } from "../shared/main-layout"
import { PlanList } from "./components/plan-lists"

export const InvestmentPlans = () => {
    return (
        <MainLayout>
            <PlanList />
        </MainLayout>
    )
}