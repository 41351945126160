import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Button, LinearProgress } from "@mui/material"
import { MainLayout } from "../../shared/main-layout"
import { SignupServices } from "../../services/signup.services"
import Countries from "../../data/countries.json"

export const Signup = () => {

    const [showPassword, setShowPassword] = useState(false);
    const _signupServices = SignupServices();

    return (
        <MainLayout>
            <div className="container-fluid user-auth" style={{ paddingBottom: '160px' }}>
                <div> </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="form-container">
                        <div>
                            <div className=" text-center">
                                <h2 className="title-head hidden-xs">GET <span>STARTED</span></h2>
                                <p className="info-form">OPEN ACCOUNT FOR FREE AND START INVESTING NOW!</p>
                            </div>
                            <form style={{ marginBottom: 50 }} onSubmit={(event) => _signupServices.HandleSignup(event)}>

                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        value={_signupServices.values.Name}
                                        onChange={(e) => _signupServices.setValues((prev) => ({ ...prev, Name: e.target.value }))}
                                        placeholder="NAME"
                                        type="search"
                                        required />
                                </div>

                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        value={_signupServices.values.Email}
                                        onChange={(e) => _signupServices.setValues((prev) => ({ ...prev, Email: e.target.value }))}
                                        placeholder="EMAIL ADDRESS"
                                        type="email"
                                        required />
                                </div>

                                {/* <div className="form-group">
                                    <input
                                        className="form-control"
                                        value={_signupServices.values.BitcoinWalletAddress}
                                        onChange={(e) => _signupServices.setValues((prev) => ({ ...prev, BitcoinWalletAddress: e.target.value }))}
                                        placeholder="WALLET ADDRESS"
                                        type="search"
                                        required />
                                </div> */}

                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        value={_signupServices.values.SecreteQuestion}
                                        onChange={(e) => _signupServices.setValues((prev) => ({ ...prev, SecreteQuestion: e.target.value }))}
                                        placeholder="SECRETE QUESTION"
                                        type="search"
                                        required />
                                </div>

                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        value={_signupServices.values.SecreteQuestionAnswer}
                                        onChange={(e) => _signupServices.setValues((prev) => ({ ...prev, SecreteQuestionAnswer: e.target.value }))}
                                        placeholder="SECRETE QUESTION ANSWER"
                                        type="search"
                                        required />
                                </div>

                                <div className="form-group">
                                    <select value={_signupServices.values?.Country} onChange={(e) => _signupServices.setValues((prev) => ({ ...prev, Country: e.target.value }))} className="form-control" style={{ color: 'white' }}>
                                        <option selected value="">please select country</option>
                                        {
                                            Countries.map((country, index) => {
                                                return (<option key={index} value={country.name}>{country.name} ({country.code})</option>)
                                            })
                                        }
                                    </select>
                                </div>

                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        value={_signupServices.values.Username}
                                        onChange={(e) => _signupServices.setValues((prev) => ({ ...prev, userName: e.target.value }))}
                                        placeholder="USERNAME"
                                        type="search"
                                        required />
                                </div>
                                <div className="form-group">
                                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: "flex-end" }}>
                                        <input
                                            style={{ paddingRight: 50 }}
                                            className="form-control"
                                            value={_signupServices.values.Password}
                                            onChange={(e) => _signupServices.setValues((prev) => ({ ...prev, Password: e.target.value }))}
                                            placeholder="PASSWORD"
                                            type={showPassword ? "text" : "password"} required />
                                        <Button style={{ width: 30, position: 'absolute' }} onClick={() => setShowPassword(!showPassword)} endIcon={showPassword ? <Visibility style={{ width: 30, height: 30, color: 'gray' }} /> : <VisibilityOff style={{ width: 30, height: 30, color: 'gray' }} />}></Button>
                                    </div>
                                </div>
                                <div className="form-group">
                                    {_signupServices.isBusy && <LinearProgress />}
                                    <button disabled={_signupServices.isBusy} className="btn btn-primary" type="submit">{_signupServices.isBusy ? "Please wait..." : "Register"}</button>
                                    <p className="text-center" style={{ marginTop: 30 }}>Already have an account ? <Link to="/account/login">Login!</Link></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}