import React, { FormEvent, ReactNode, useCallback, useEffect, useState } from "react"
import { Footer } from "./footer";
import { Link, useLocation } from "react-router-dom";
import { AccountBalanceWallet, OpenWithTwoTone, PeopleAlt, ArrowDownward, ArrowForward, Logout, Person, BarChart, Money, DashboardCustomize, SettingsApplications, Settings, Email, Calculate } from '@mui/icons-material';
import { CustomNavLink } from "./custom-navlink";
import { LoginServices } from "../services/login.services";
import { Utils } from "../core/utils";
import PageRoutes from "../data/page-routes.json"

type MainLayoutProps = {
    children: ReactNode
}

export const MainLayout = ({ children }: MainLayoutProps) => {
    const _loginServices = LoginServices();

    const location = useLocation();

    const [sidebarStatus, setSidebarStatus] = useState<any>(localStorage.getItem("sidebarStatus"));
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const handleResize = useCallback(() => {
        setWindowWidth(window.innerWidth);
    }, [setWindowWidth]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    const HandleSideBarStatus = useCallback(() => {
        const sidebarStatus = localStorage.getItem("sidebarStatus")
        if (sidebarStatus && sidebarStatus === "Visible") {
            setSidebarStatus("Hidden")
            localStorage.setItem("sidebarStatus", "Hidden")
        } else {
            setSidebarStatus("Visible")
            localStorage.setItem("sidebarStatus", "Visible")
        }
    }, [setSidebarStatus])


    const HideSideBarStatus = useCallback(() => {
        setSidebarStatus("Hidden")
        localStorage.setItem("sidebarStatus", "Hidden")
    }, [setSidebarStatus])

    const role = localStorage.getItem("role")
    const userRole = Utils.DecryptData(role)
    const isLoggedIn = localStorage.getItem("accessToken");
    const websiteSettings = localStorage.getItem("websiteSettings");
    const activities = localStorage.getItem("activities");

    const [showWebsiteSetting, setShowWebsiteSetting] = useState(websiteSettings)
    const [showActivities, setShowActivities] = useState(activities)

    const OnShowWebsiteSetting = useCallback((event: FormEvent) => {
        event.preventDefault();
        if (showWebsiteSetting === "Visible") {
            localStorage.setItem("websiteSettings", "Hidden")
            setShowWebsiteSetting("Hidden")
        }
        else {
            localStorage.setItem("websiteSettings", "Visible")
            setShowActivities("Hidden")
            setShowWebsiteSetting("Visible")
        }
    }, [setShowWebsiteSetting, showWebsiteSetting])

    const OnShowActivities = useCallback((event: FormEvent) => {
        event.preventDefault();
        if (showActivities === "Visible") {
            localStorage.setItem("activities", "Hidden")
            setShowActivities("Hidden")
        }
        else {
            localStorage.setItem("activities", "Visible")
            setShowWebsiteSetting("Hidden")
            setShowActivities("Visible")
        }
    }, [setShowActivities, showActivities])

    return (
        <div className="wrapper">
            <div style={{ flexDirection: 'row', display: 'flex', margin: 0, padding: 0 }}>
                <div style={{ padding: 0, margin: 0, height: '90vh', display: sidebarStatus === "Visible" ? "block" : 'none', width: windowWidth < 910 ? "100%" : "300px", position: windowWidth < 910 ? "absolute" : "relative", zIndex: 9999 }}>
                    <div style={{ overflowY: "visible", padding: 0, margin: 0, width: "300px", backgroundColor: "#1d1d1d", height: '95vh', overflowX: 'hidden', position: "fixed", zIndex: 9999 }}>

                        <div className="text-center" style={{ display: 'flex', flexDirection: 'row', justifyContent: windowWidth < 910 ? 'space-between' : "center", alignItems: 'center', width: '100%', padding: 15, zIndex: 999 }}>
                            <Link to={location.pathname} onClick={() => HideSideBarStatus()}><img alt="logo" src="./logo.png" style={{ width: 75, height: 75 }} /></Link>
                            {windowWidth < 910 && <button onClick={() => HideSideBarStatus()} className="btn btn-services btn-order pull-right" style={{ position: 'absolute', top: 15, right: 10, fontWeight: 'bolder', fontSize: 18 }}>X</button>}
                        </div>

                        <CustomNavLink
                            action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                            title={<div style={{ width: '100%', margin: 0, padding: 0 }}><label style={{ width: '100%', margin: 0, padding: 0 }}>@{userDetails?.userName}</label> <p style={{ margin: 0, padding: 0, color: 'silver' }}>View your profile</p></div>}
                            PageRoute={PageRoutes.profile.url}
                            IconName={<Person color="inherit" style={{ marginRight: 8, width: 23, height: 23 }} />}
                        />

                        {isLoggedIn && userRole &&
                            <div style={{ marginTop: 5, padding: 0 }}>
                                <CustomNavLink
                                    action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                    title={PageRoutes.dashboard.title}
                                    PageRoute={PageRoutes.dashboard.url}
                                    IconName={<DashboardCustomize color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                />

                                {(isLoggedIn && userRole && userRole?.toLowerCase() === "admin") &&
                                    <>
                                        <CustomNavLink
                                            action={(event) => OnShowWebsiteSetting(event)}
                                            title="Website Settings"
                                            PageRoute="/website-settings"
                                            IconName={
                                                showWebsiteSetting && showWebsiteSetting === "Visible" ?
                                                    <ArrowDownward color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} /> :
                                                    <ArrowForward color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />
                                            }
                                        />
                                        <div style={{ display: showWebsiteSetting && showWebsiteSetting === "Visible" ? "block" : 'none', paddingLeft: 10, backgroundColor: '#2f3d35' }}>
                                            <CustomNavLink
                                                action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                                title={PageRoutes.basicSettings.title}
                                                PageRoute={PageRoutes.basicSettings.url}
                                                IconName={<Settings color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                            />

                                            <CustomNavLink
                                                action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                                title={PageRoutes.planSettings.title}
                                                PageRoute={PageRoutes.planSettings.url}
                                                IconName={<Settings color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                            />

                                            <CustomNavLink
                                                action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                                title={PageRoutes.coinSettings.title}
                                                PageRoute={PageRoutes.coinSettings.url}
                                                IconName={<Settings color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                            />

                                            <CustomNavLink
                                                action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                                title={PageRoutes.compoundingSettings.title}
                                                PageRoute={PageRoutes.compoundingSettings.url}
                                                IconName={<Settings color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                            />
                                        </div>

                                        <CustomNavLink
                                            action={(event) => OnShowActivities(event)}
                                            title="Manage Activities"
                                            PageRoute="/manage-activities"
                                            IconName={
                                                showActivities && showActivities === "Visible" ?
                                                    <ArrowDownward color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} /> :
                                                    <ArrowForward color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />
                                            }
                                        />
                                        <div style={{ display: showActivities && showActivities === "Visible" ? "block" : 'none', paddingLeft: 10, backgroundColor: '#2f3d35' }}>
                                            <CustomNavLink
                                                action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                                title={PageRoutes.manageUser.title}
                                                PageRoute={PageRoutes.manageUser.url}
                                                IconName={<SettingsApplications color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                            />

                                            <CustomNavLink
                                                action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                                title={PageRoutes.manageDeposit.title}
                                                PageRoute={PageRoutes.manageDeposit.url}
                                                IconName={<SettingsApplications color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                            />

                                            <CustomNavLink
                                                action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                                title={PageRoutes.manageWithdraws.title}
                                                PageRoute={PageRoutes.manageWithdraws.url}
                                                IconName={<SettingsApplications color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                            />

                                            <CustomNavLink
                                                action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                                title={PageRoutes.manageTransaction.title}
                                                PageRoute={PageRoutes.manageTransaction.url}
                                                IconName={<SettingsApplications color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                            />
                                        </div>

                                        <CustomNavLink
                                            action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                            title={PageRoutes.compundInvestment.title}
                                            PageRoute={PageRoutes.compundInvestment.url}
                                            IconName={<Calculate color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                        />

                                        <CustomNavLink
                                            action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                            title={PageRoutes.mailingSystem.title}
                                            PageRoute={PageRoutes.mailingSystem.url}
                                            IconName={<Email color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                        />
                                    </>
                                }

                                <CustomNavLink
                                    action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                    title={PageRoutes.investmantPlans.title}
                                    PageRoute={PageRoutes.investmantPlans.url}
                                    IconName={<OpenWithTwoTone color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                />

                                <CustomNavLink
                                    action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                    title={PageRoutes.deposits.title}
                                    PageRoute={PageRoutes.deposits.url}
                                    IconName={<Money color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                />

                                <CustomNavLink
                                    action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                    title={PageRoutes.withdraws.title}
                                    PageRoute={PageRoutes.withdraws.url}
                                    IconName={<AccountBalanceWallet color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                />

                                <CustomNavLink
                                    action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                    title={PageRoutes.earning.title}
                                    PageRoute={PageRoutes.earning.url}
                                    IconName={<BarChart color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                />

                                <CustomNavLink
                                    action={() => windowWidth < 910 ? HideSideBarStatus() : null}
                                    title={PageRoutes.referrals.title}
                                    PageRoute={PageRoutes.referrals.url}
                                    IconName={<PeopleAlt color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                />

                                <CustomNavLink
                                    action={() => _loginServices.HandleLogout()}
                                    title={PageRoutes.logOut.title}
                                    PageRoute={PageRoutes.logOut.url}
                                    IconName={<Logout color="inherit" style={{ marginRight: 8, marginTop: 3, width: 20, height: 20 }} />}
                                />
                            </div>
                        }

                    </div>
                    <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', width: '100%', height: '100vh' }} onClick={() => HideSideBarStatus()}></div>
                </div>
                <div style={{ width: '100%', overflow: 'hidden', margin: 0, padding: 0, }}>
                    <header className="header">
                        <div className="container">
                            <div className="row">
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: windowWidth < 910 ? 'center' : 'flex-start', justifyContent: windowWidth < 910 ? 'center' : 'flex-start' }} className="main-logo col-xs-12 col-md-3 col-lg-2 hidden-xs">
                                    {localStorage.getItem("accessToken") && <Link to={window.location.pathname} onClick={() => HandleSideBarStatus()} style={{ position: 'relative', fontSize: 30, backgroundColor: 'transparent', marginRight: 6, left: 0, padding: 10, border: 0 }}><i className={sidebarStatus === "Visible" ? "fa fa-close" : "fa fa-list"}><span style={{ display: 'none' }}>.</span></i></Link>}
                                    <Link to="/" onClick={() => HideSideBarStatus()} style={{ marginTop: -12 }}>
                                        <h3 style={{ color: 'white', fontSize: '25' }}>{process.env.REACT_APP_APP_NAME}</h3>
                                        {/* <img id="logo" className="img-responsive" src="images/logo-dark.png" alt="logo" /> */}
                                    </Link>
                                </div>
                                <div className="col-md-7 col-lg-7">
                                    <ul className="unstyled bitcoin-stats text-center">
                                        <li>
                                            <h6>9,450 USD</h6><span>Last invetstent price</span></li>
                                        <li>
                                            <h6>+5.26%</h6><span>24 hour price</span></li>
                                        <li>
                                            <h6>12.820 BTC</h6><span>24 hour volume</span></li>
                                        <li>
                                            <h6>2,231,775</h6><span>active investors</span></li>
                                        <li>
                                            <div className="btcwdgt-price" data-bw-theme="light" data-bw-cur="usd"></div>
                                            <span>Live Bitcoin price</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    {localStorage.getItem("accessToken") ?
                                        <ul className="unstyled user">
                                            <li className="sign-in"><Link onClick={(event) => { event.preventDefault(); HandleSideBarStatus() }} to="/dashboard" className="btn btn-primary"><i className="fa fa-list"></i> Dashboard</Link></li>
                                            {windowWidth < 910 && <li className="sign-in"><Link onClick={(event) => { event.preventDefault(); _loginServices.HandleLogout() }} to={window.location.pathname} className="btn btn-primary"><i className="fa fa-user"></i> Log out</Link></li>}
                                        </ul> :
                                        <ul className="unstyled user">
                                            <li className="sign-in"><Link to="/account/login" className="btn btn-primary"><i className="fa fa-user"></i> sign in</Link></li>
                                            <li className="sign-up"><Link to="/account/register" className="btn btn-primary"><i className="fa fa-user-plus"></i> register</Link></li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                        <nav className="site-navigation navigation" id="site-navigation">
                            <div className="container">
                                <div className="site-nav-inner">
                                    <Link onClick={() => HideSideBarStatus()} className="logo-mobile" to="/">
                                        {/* <img id="logo-mobile" className="img-responsive" src="images/logo-dark.png" alt="" /> */}
                                        <h3 style={{ color: 'white', fontSize: '25' }}>{process.env.REACT_APP_APP_NAME}</h3>
                                    </Link>
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                    <div className="collapse navbar-collapse navbar-responsive-collapse">
                                        <ul className="nav navbar-nav">
                                            <li><Link className="active" to="/">Home</Link></li>
                                            <li><Link to="/about">About Us</Link></li>
                                            <li><Link to="/investment-plans">Investment Plans</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="site-search">
                                <div className="container">
                                    <input type="text" placeholder="type your keyword and hit enter ..." />
                                    <span className="close">U+0078</span>
                                </div>
                            </div>
                        </nav>
                    </header>
                    <div id="google_translate_element" style={{ position: 'absolute', top: '5px', marginLeft: '30px', width: '100%', zIndex: 999 }}></div>
                    {children}
                    <Footer />
                </div>
            </div>

        </div>
    )
}