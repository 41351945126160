import { CurrencyExchange } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Utils } from "../core/utils";

export default function DashboardDataReportAdmin() {

    const [dataReport, setDataReport] = useState<any>()
    useEffect(() => {
        const response = localStorage.getItem("dataReport") as unknown as any
        if (response) {
            setDataReport(JSON.parse(response));
        }
    }, [])

    return (
        <div className="row dashboard-report">
            <div className="col-md-6">
                <div className="dashboard-card">
                    <h3>All Balance</h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.allBalance > 0 ? dataReport?.allBalance : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6">
                <div className="dashboard-card">
                    <h3>All Active Deposit</h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.allActiveDeposit > 0 ? dataReport?.allActiveDeposit : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6">
                <div className="dashboard-card">
                    <h3>All Deposit</h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.allDeposit > 0 ? dataReport?.allDeposit : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6">
                <div className="dashboard-card">
                    <h3>Users</h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{dataReport?.users > 0 ? dataReport?.users : 0}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>Deposits</h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.Deposits > 0 ? dataReport?.Deposits : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>WITHDRAWALS</h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.withdrawals > 0 ? dataReport?.withdrawals : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>Plans</h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{dataReport?.plans > 0 ? dataReport?.plans : 0}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        ACTIVE INVESTMENTS
                    </h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.activeInvestments > 0 ? dataReport?.activeInvestments : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>COMPLETED INVESTMENTS</h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.completedInvestments > 0 ? dataReport?.completedInvestments : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '100%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        PENDING DEPOSITS
                    </h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.pendingDeposits > 0 ? dataReport?.pendingDeposits : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        CONFIRMED DEPOSITS
                    </h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.confirmedDeposits > 0 ? dataReport?.confirmedDeposits : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        PENDING WITHDRAWALS
                    </h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.pendingWithdrawals > 0 ? dataReport?.pendingWithdrawals : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        COMPLETED WITHDRAWALS
                    </h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">

                            <h4 className="text-success">{Utils.Currency.format(dataReport?.completedWithdrawals > 0 ? dataReport?.completedWithdrawals : 0)}</h4>
                            <span className="progress text-center" style={{ width: '100%', height: '5px' }}>
                                <span className="progress-bar bg-info" style={{ width: '90%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <CurrencyExchange style={{ color: 'white' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}