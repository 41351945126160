import React, { useCallback, useEffect, useRef, useState } from "react"
import Moment from 'react-moment';
import { ProtectdPageWrapper } from "../_wrapper"
import { ThunkConfig } from "../../redux/config";
import { Utils } from "../../core/utils";
import CustomModal from "../../shared/custom-modal";

const WithdrawModel = { amount: 0, walletType: "", bitcoinAddress: "", withdrawalType: "" }

export const ClientWithdraws = () => {

    const [isBusy, setIsBusy] = useState(false)
    const modalRef = useRef<any>();
    const [withdraw, setWithdraw] = useState(WithdrawModel);

    const [withdrawalList, setWithdrawalList] = useState<any[]>(JSON.parse(localStorage.getItem("userWithdrawals")))
    const [walletDetails, setWalletDetails] = useState<any>(JSON.parse(localStorage.getItem("walletDetails")))
    const [walletAddressList] = useState<any[]>(localStorage.getItem("userWalletAddress") ? JSON.parse(localStorage.getItem("userWalletAddress")) : [])

    const GetUserWithdrawals = async () => {
        const userWithdrawal: any[] = await ThunkConfig.HTTPGetRequest(`Withdrawal/get-user-withdrawal-request`);
        if (userWithdrawal.length > 0) {
            localStorage.setItem("userWithdrawals", JSON.stringify(userWithdrawal))
            setWithdrawalList(userWithdrawal)
        }

        const walletDetails = await ThunkConfig.HTTPGetRequest(`wallet/get-user-wallet`);
        if (walletDetails) {
            setWalletDetails(walletDetails)
        }
    }

    async function handleWithdrawal(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        try {
            setIsBusy(true)

            const response = await ThunkConfig.HTTPPostRequest("withdrawal/withdraw", withdraw);
            if (modalRef.current) {
                modalRef.current.toggleModal()
            }
            if (response.statusCode === 200) {
                setWithdraw(WithdrawModel);
                await GetUserWithdrawals();
            }
            Utils.AlertToast(response?.message, "info", "Okay!", true)
            setIsBusy(false)
        } catch (error: any) {
            setIsBusy(false)
            console.error("error: ", error)
            Utils.AlertToast(error?.message, "error", "Okay!", true)
        }
    }

    useEffect(() => {
        GetUserWithdrawals();
    }, [setWithdrawalList, withdrawalList])


    const HandleModal = () => {
        if (modalRef.current) {
            modalRef.current.toggleModal()
        }
    }

    return (
        <ProtectdPageWrapper title="Withdraws">
            <section className="shop-cart" style={{ margin: 0 }}>
                <div className="container">

                    <div className='row mb-5'>
                        <div className='col-md-6'><h2>Withdraw</h2></div>
                        <div className='col-md-6 pull-right'><button disabled={isBusy} style={{ width: "150px" }} className='btn btn-success pull-right' onClick={() => HandleModal()}>Withdraw</button></div>
                    </div>

                    <div className="col-xs-12 table-responsive">
                        <table className="table order text-center" >
                            <thead>
                                <tr>
                                    <th style={{ paddingLeft: 20 }}>S/N</th>
                                    <th style={{ minWidth: 200 }}>Transaction Id</th>
                                    <th style={{ minWidth: 200 }}>Amount</th>
                                    <th style={{ minWidth: 200 }}>Charge</th>
                                    <th style={{ minWidth: 200 }}>Wallet Type</th>
                                    <th style={{ minWidth: 200 }}>Address</th>
                                    <th style={{ minWidth: 200 }}>Withdraw from</th>
                                    <th style={{ minWidth: 200 }}>Status</th>
                                    <th style={{ minWidth: 200 }}>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    withdrawalList?.map((item: any, index) => {
                                        return (
                                            <tr key={index} style={{ color: 'black' }}>
                                                <td className="text-left" style={{ paddingLeft: 20 }}><span className="price">{index + 1}.</span></td>
                                                <td className="text-left"><h6 className="product">{item?.TransactionId}</h6></td>
                                                <td className="text-left"><span className="price" style={{ color: 'lightgreen' }}>{Utils.Currency.format(item.Amount)}</span></td>
                                                <td className="text-left"><span className="price" style={{ color: 'lightgreen' }}>{Utils.Currency.format(item.Charges ? item.Charges : 0)}</span></td>
                                                <td className="text-left"><span className="price">{item?.WalletType}</span></td>
                                                <td className="text-left"><span className="price">{item?.BitcoinAddress}</span></td>
                                                <td className="text-left"><span className="price">{item?.WithdrawalType}</span></td>
                                                <td className="text-left"><span className="price" style={{ color: item?.Status === "Pending" ? "red" : 'white' }}>{item?.Status}</span></td>
                                                <td className="text-left"><span className="price"><Moment fromNow>{item.ActionDate}</Moment> </span></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {!withdrawalList || withdrawalList?.length === 0 ? <h3 className="text-center" style={{ color: 'silver' }}>No withdrawal record found!</h3> : null}
                    </div>
                </div>
            </section>

            <CustomModal width="40%" height="auto" title="Withdraw Request" ref={modalRef} isBusy={false} >
                <form onSubmit={(event) => handleWithdrawal(event)}>
                    <div className="form-group">
                        <label>Amount</label>
                        <input
                            className="form-control"
                            style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}
                            onChange={(e: any) => setWithdraw((prev) => ({ ...prev, amount: Number(e.target.value.replaceAll(/[^0-9]/g, "")) }))}
                            value={withdraw?.amount}
                            placeholder="Amount"
                            type="search"
                            required />
                    </div>

                    <div className="form-group">
                        <label>Withdraw from</label>
                        <select
                            onChange={(e) => setWithdraw((prev: any) => ({ ...prev, withdrawalType: e.target.value }))}
                            value={withdraw?.withdrawalType}
                            required
                            className="form-control"
                            style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                            <option selected disabled value=""> ---</option>
                            <option value="Tether USDT">Profits</option>
                            <option value="Balance">Balance</option>
                            <option value="ReferralBonus">Referral Bonus</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Select Wallet Type</label>
                        <select
                            onChange={(e) => setWithdraw((prev: any) => ({ ...prev, walletType: e.target.value }))}
                            value={withdraw?.walletType}
                            required
                            className="form-control"
                            style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                            <option selected disabled value=""> ---</option>
                            <option value="Bitcoin">Bitcoin</option>
                            <option value="Ethereum">Ethereum</option>
                            <option value="Tether USDT">Tether USDT</option>
                            <option value="Binance">Binance</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Select Wallet Address</label>
                        <select
                            onChange={(e) => setWithdraw((prev: any) => ({ ...prev, bitcoinAddress: e.target.value }))}
                            value={withdraw?.bitcoinAddress}
                            required
                            className="form-control"
                            style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                            <option selected disabled value=""> ---</option>
                            {
                                walletAddressList.length > 0 ?
                                    walletAddressList.filter(x => x.coin === withdraw?.walletType).map((item) => {
                                        return <option value={item?.address}>{item?.address}</option>
                                    })
                                    : null
                            }
                        </select>
                    </div>

                    <div className="row" style={{ marginTop: 40 }}>
                        <div className="col-md-8" style={{ display: 'flex', flexDirection: 'row' }}>
                        </div>

                        <div className="col-md-4 text-right">
                            <button type="submit" className="btn btn-primary" disabled={isBusy}>{isBusy ? "Please wait..." : "Request"}</button>
                        </div>
                    </div>
                </form>
            </CustomModal>

        </ProtectdPageWrapper>
    )
}