import React, { useState, useRef, useCallback, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { TextField } from '@mui/material';
import { Edit, Delete } from "@mui/icons-material";
import { Utils } from '../../core/utils';
import { ThunkConfig } from '../../redux/config';
import CustomAlert from '../../shared/custom-alert';
import CustomDialog from '../../shared/custom-dialog';
import CustomModal from '../../shared/custom-modal';
import { ProtectdPageWrapper } from '../../pages/_wrapper';

export function Compounding() {

    const [messages, setMessages] = useState<any>();
    const [isBusy, setBusy] = useState(false);
    const [values, setValues] = useState<any>();
    const alertRef = useRef(null);
    const dialogRef = useRef(null);
    const modalRef = useRef(null);

    const compoundings = localStorage.getItem("compoundings")
    const [compoundingList, setCompoundingList] = useState<any[]>(compoundings ? JSON.parse(compoundings) : [])

    const GetCompounding = useCallback(async () => {
        const response = await ThunkConfig.HTTPGetRequest(`compounding/get-all`);
        if (response) {
            localStorage.setItem("compoundings", JSON.stringify(response))
            setCompoundingList(response)
        }
    }, [])

    const OnOpenModal = () => {
        setValues(null);
        modalRef.current.toggleModal();
    }

    const OnEditRequest = (item) => {
        setValues(item);
        modalRef.current.toggleModal();
    }

    const OnDeleteRequest = (item, name) => {
        setBusy(true);
        let newMessage = (<><b className='mb-4'>{name}, will be deleted.</b> <br /> <em className='text-danger'>Are you sure?</em></>);

        setMessages(newMessage);
        setValues(item);
        setBusy(false);
        dialogRef.current.toggleDialog();
    }

    const OnDelete = async () => {
        try {
            setBusy(true);
            const response = await ThunkConfig.HTTPPostRequest(`compounding/delete?id=${values?.id}`, null);
            if (response && response?.statusCode === 200) {
                await GetCompounding();
            }
            dialogRef.current.toggleDialog();
            setBusy(false);
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
            setBusy(false);
        }
    }

    const OnToggle = useCallback(async (id: string) => {
        try {
            setBusy(true);
            //
            const response = await ThunkConfig.HTTPPostRequest(`compounding/toggle?id=${id}`, null);
            if (response) {
                await GetCompounding();
            }
            setBusy(false);
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
            setBusy(false);
        }
    }, [GetCompounding])

    const SaveData = useCallback(async () => {
        try {
            setBusy(true);
            const response = await ThunkConfig.HTTPPostRequest(`compounding/form-data`, values);
            if (response && response?.statusCode === 200) {
                await GetCompounding();
                setValues(null);
                modalRef.current.toggleModal();
            }
            setBusy(false);
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
        }
    }, [values, GetCompounding])

    useEffect(() => {
        GetCompounding();
    }, [GetCompounding])

    return (
        <ProtectdPageWrapper title='Manage Compounding'>
            <section className="shop-cart" style={{ margin: 0 }}>
                <div className="container">
                    <div className='row mb-5'>
                        <div className='col-md-12 pull-right'><button disabled={isBusy} className='btn btn-success pull-right' onClick={OnOpenModal} style={{ width: 100 }}>New</button></div>
                    </div>
                    <div className="col-xs-12 table-responsive">
                        <table className="table order text-center" >
                            <thead>
                                <tr>
                                    <th style={{ paddingLeft: 20 }}>S/N</th>
                                    <th>Name</th>
                                    <th>Compound</th>
                                    <th>Status</th>
                                    <th colSpan={2}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    compoundingList?.map((item: any, index) => {
                                        return (
                                            <tr key={index} style={{ color: 'black' }}>
                                                <td className="text-left" style={{ paddingLeft: 20 }}><span className="price">{index + 1}.</span></td>
                                                <td className="text-left"><h6 className="product">{item?.name}</h6></td>
                                                <td className="text-left"><span className="price">{item?.compound}</span></td>
                                                <td className="text-left">
                                                    <FormControlLabel control={<Switch color='success' disabled={isBusy} checked={item.isActive} onChange={() => OnToggle(item.id)} />} label={item.isActive ? "Active" : "Disabled"} />
                                                </td>
                                                <td className="text-left">
                                                    <Edit style={{ width: 30, height: 30, cursor: 'pointer' }} className='mr-4' color="primary" onClick={() => OnEditRequest(item)} />
                                                </td>
                                                <td className="text-left">
                                                    <Delete style={{ width: 30, height: 30, cursor: 'pointer' }} color="error" onClick={() => OnDeleteRequest(item, item.name)} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {compoundingList.length === 0 && <h3 className="text-center" style={{ color: 'silver' }}>No compounding has been setup yet</h3>}
                    </div>
                </div>
            </section>

            <CustomModal ref={modalRef} width={'600px'} height={'200px'} title={'Compounding'} isBusy={false}>

                <div className="row p-3 d-flex align-content-center align-items-center justify-content-between">

                    <div className="col-md-12">
                        <TextField value={values?.name} onChange={(e) => { setValues({ ...values, name: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" label="Name" variant="standard" />
                    </div>

                    <div className="col-md-12">
                        <TextField type="number" value={values?.compound} onChange={(e) => { setValues({ ...values, compound: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" label="Compound" variant="standard" />
                    </div>

                    <div className="col-md-12" style={{ marginTop: 20 }}>
                        <button disabled={isBusy} onClick={() => SaveData()} type="submit" className="btn btn-primary btn-order pull-right">{isBusy ? "Saving..." : "Save"}</button>
                    </div>

                </div>

            </CustomModal>

            <CustomDialog ref={dialogRef} >
                <h4 className='text-success text-center mb-5 mt-3'>{messages}</h4>
                <div className='row'>
                    <div className='col-md-6'><button disabled={isBusy} className='btn btn-default' onClick={() => dialogRef.current.toggleDialog()}>No</button></div>
                    <div className='col-md-6 text-right'>
                        <button disabled={isBusy} onClick={() => OnDelete()} type="submit" className="btn btn-primary btn-order pull-right">{isBusy ? "Deleting..." : "Yes"}</button>
                    </div>
                </div>
            </CustomDialog>
            <CustomAlert ref={alertRef}><h3 className='text-center'> {messages}</h3></CustomAlert>

        </ProtectdPageWrapper>
    );
}