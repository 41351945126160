import React, { useState } from "react"
import { Link } from "react-router-dom"
import { MainLayout } from "../../shared/main-layout"
import { LoginServices } from "../../services/login.services";
import LinearProgress from "@mui/material/LinearProgress";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button } from "@mui/material";

export const Login = () => {

    const _loginServices = LoginServices();

    const [showPassword, setShowPassword] = useState(false);

    return (
        <MainLayout>
            <div className="container-fluid user-auth">
                <div> </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="form-container">
                        <div>
                            <div className=" text-center">
                                <h2 className="title-head hidden-xs">member <span>login</span></h2>
                                <p className="info-form">Login to access our services</p>
                            </div>
                            <form onSubmit={(event) => _loginServices.HandleLogin(event)}>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        value={_loginServices.values.userName}
                                        onChange={(e) => _loginServices.setValues((prev) => ({ ...prev, userName: e.target.value }))}
                                        placeholder="USERNAME"
                                        type="search"
                                        required />
                                </div>
                                <div className="form-group">
                                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: "flex-end" }}>
                                        <input
                                            style={{ paddingRight: 50 }}
                                            className="form-control"
                                            value={_loginServices.values.password}
                                            onChange={(e) => _loginServices.setValues((prev) => ({ ...prev, password: e.target.value }))}
                                            placeholder="PASSWORD"
                                            type={showPassword ? "text" : "password"} required />
                                        <Button style={{ width: 30, position: 'absolute' }} onClick={() => setShowPassword(!showPassword)} endIcon={showPassword ? <Visibility style={{ width: 30, height: 30, color: 'gray' }} /> : <VisibilityOff style={{ width: 30, height: 30, color: 'gray' }} />}></Button>
                                    </div>
                                </div>
                                <div className="form-group">
                                    {_loginServices.isBusy && <LinearProgress />}
                                    <button disabled={_loginServices.isBusy} className="btn btn-primary" type="submit">{_loginServices.isBusy ? "Please wait..." : "Login"}</button>
                                    <p className="text-center" style={{ marginTop: 30 }}>don't have an account ? <Link to="/account/register">register now</Link></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}