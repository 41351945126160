import React from "react"
import { Link } from "react-router-dom"
import { MainLayout } from "../shared/main-layout"
import { PlanList } from "./components/plan-lists"

export const LandingPage = () => {

    return (
        <MainLayout>
            {/* <iframe className="grid-data" title="grid data" src="https://www.exchangerates.org.uk/widget/ER-LRTICKER.php?w=auto&amp;s=1&amp;mc=GBP&amp;fs=11px&amp;fc=000044&amp;lc=19335C&amp;lhc=faa31c&amp;vc=FE9A00&amp;vcu=008000&amp;vcd=FF0000&amp;"></iframe> */}
            <div id="main-slide" className="carousel slide carousel-fade" data-ride="carousel">
                <ol className="carousel-indicators visible-lg visible-md">
                    <li data-target="#main-slide" data-slide-to="0" className="active"></li>
                    <li data-target="#main-slide" data-slide-to="1"></li>
                    <li data-target="#main-slide" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">

                    <div className="item bg-parallax item-1">
                        <div className="slider-content">
                            <div className="col-md-12">
                                <div className="container">
                                    <div className="slider-text text-center">
                                        <h3 className="slide-title"><span>Investment</span> Platform <br />You can <span>Trust</span> </h3>
                                        <p>
                                            <a href="pricing.html" className="slider btn btn-primary">our prices</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item active bg-parallax item-2">
                        <div className="slider-content">
                            <div className="container">
                                <div className="slider-text text-center">
                                    <h3 className="slide-title"><span>Secure</span> and <span>Easy Way</span><br /> To Invest</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="left carousel-control" href="index-2.html#main-slide" data-slide="prev">
                    <span><i className="fa fa-angle-left"></i></span>
                </a>
                <a className="right carousel-control" href="index-2.html#main-slide" data-slide="next">
                    <span><i className="fa fa-angle-right"></i></span>
                </a>
            </div>

            <section className="features">
                <div className="container">
                    <div className="row features-row">
                        <div className="feature-box col-md-4 col-sm-12">
                            <span className="feature-icon">
                                <img id="download-bitcoin" src="images/icons/orange/add-bitcoins.png" alt="download bitcoin" />
                            </span>
                            <div className="feature-box-content">
                                <h3>Profit Generation</h3>
                                <p>Enjoy profit generation and account stability from your investments continously.</p>
                            </div>
                        </div>
                        <div className="feature-box two col-md-4 col-sm-12">
                            <span className="feature-icon">
                                <img id="add-bitcoins" src="images/icons/orange/add-bitcoins.png" alt="add bitcoins" />
                            </span>
                            <div className="feature-box-content">
                                <h3>Commissions</h3>
                                <p>We are duly registered and certified company which stand to help you earn commissions on your investment.</p>
                            </div>
                        </div>
                        <div className="feature-box three col-md-4 col-sm-12">
                            <span className="feature-icon">
                                <img id="buy-sell-bitcoins" src="images/icons/orange/buy-sell-bitcoins.png" alt="buy and sell bitcoins" />
                            </span>
                            <div className="feature-box-content">
                                <h3>Invest for Longterm</h3>
                                <p>We provide global expertise with professionals working from our office to investors from any part of the world</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-us">
                <div className="container">
                    <div className="row about-content">
                        <div className="col-sm-12 col-md-5 col-lg-6 text-center">
                            {/* <div className="btcwdgt-chart" data-bw-theme="dark"></div> */}
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h3 className="title-about">WE ARE {process.env.REACT_APP_APP_NAME}</h3>
                            <p className="about-text">A place for everyone who wants to simply invest in cryptocurrency. Our investment professionals invest savers funds in financial instruments and manage the investments to ensure optimum return. To ensure security of savers funds, a company registered with the Security and Exchange Commission (SEC) on behalf of savers. These assets are marked to market periodically to ensure savers are protected.</p>
                            <ul className="nav nav-tabs">
                                <li className="active"><a data-toggle="tab" href="#menu3">Our Mission</a></li>
                            </ul>
                            <div className="tab-content">
                                <div id="menu1" className="tab-pane fade in active">
                                    <p>We are here because we are passionate about open, transparent markets and aim to be a major driving force in widespread adoption of cryptocurrency. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="image-block">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 ts-padding img-block-left">
                            <div className="gap-20"></div>
                            <div className="row">
                                <div className="col-sm-6 col-md-6 col-xs-12">
                                    <div className="feature text-center">
                                        <span className="feature-icon">
                                            <img id="strong-security" src="images/icons/orange/strong-security.png" alt="strong security" />
                                        </span>
                                        <h3 className="feature-title">Strong Security</h3>
                                        <p>Protection against DDoS attacks, <br />full data encryption</p>
                                    </div>
                                </div>
                                <div className="gap-20-mobile"></div>
                                <div className="col-sm-6 col-md-6 col-xs-12">
                                    <div className="feature text-center">
                                        <span className="feature-icon">
                                            <img id="world-coverage" src="images/icons/orange/world-coverage.png" alt="world coverage" />
                                        </span>
                                        <h3 className="feature-title">World Coverage</h3>
                                        <p>Providing services in 99% countries<br /> around all the globe</p>
                                    </div>
                                </div>
                            </div>
                            <div className="gap-20"></div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-xs-12">
                                    <div className="feature text-center">
                                        <span className="feature-icon">
                                            <img id="payment-options" src="images/icons/orange/payment-options.png" alt="payment options" />
                                        </span>
                                        <h3 className="feature-title">Payment Options</h3>
                                        <p>Popular methods: Visa, MasterCard, <br />bank transfer, cryptocurrency</p>
                                    </div>
                                </div>
                            </div>
                            <div className="gap-20"></div>
                            <div className="row">
                                <div className="col-sm-6 col-md-6 col-xs-12">
                                    <div className="feature text-center">
                                        <span className="feature-icon">
                                            <img id="cost-efficiency" src="images/icons/orange/cost-efficiency.png" alt="cost efficiency" />
                                        </span>
                                        <h3 className="feature-title">Cost efficiency</h3>
                                        <p>Reasonable trading fees for takers<br /> and all market makers</p>
                                    </div>
                                </div>
                                <div className="gap-20-mobile"></div>
                                <div className="col-sm-6 col-md-6 col-xs-12">
                                    <div className="feature text-center">
                                        <span className="feature-icon">
                                            <img id="high-liquidity" src="images/icons/orange/high-liquidity.png" alt="high liquidity" />
                                        </span>
                                        <h3 className="feature-title">High Liquidity</h3>
                                        <p>Fast access to high liquidity orderbook<br /> for top currency pairs</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 ts-padding bg-image-2">
                            <div>
                                <div className="text-center">
                                    <Link className="button-video mfp-youtube" to="/"></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <PlanList />

            <iframe title="update" height="100vh" src="https://widget.coinlib.io/widget?type=full_v2&amp;theme=dark&amp;cnt=10&amp;pref_coin_id=1505&amp;graph=yes"
                style={{ overflow: 'visible', backgroundColor: 'transparent', width: "100%", height: "100vh", border: 0, margin: 0, padding: 0 }}>  </iframe>

        </MainLayout>
    )
}