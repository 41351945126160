import axios, { AxiosInstance } from "axios";

export class ThunkConfig {

    static AxiosConfig = async (): Promise<AxiosInstance> => {
        try {
            // const token = await IndexedDB.GetData(IndexedDB.StoreNames.accessToken, IndexedDB.StoreKeys.accessToken)
            const token: any = localStorage.getItem("accessToken");
            return axios.create({
                baseURL: `${process.env.REACT_APP_SERVER_BASEURL}api/`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                }
            })
        } catch (error) {
            console.error("axios-config-error:", error)
            return axios;
        }
    };

    static HTTPGetRequest = async (url: any): Promise<any> => {
        try {
            return new Promise(async (resolve, reject) => {
                const httpRequest = await this.AxiosConfig();
                const response = await httpRequest.get(url);
                return resolve(response?.data);
            });
        } catch (error) {
            console.log("http-get-request: ", error)
            return { status: 400, message: "Something went wrong. Please try again", data: null };
        }
    }

    static HTTPPostRequest = async (url: any, data: any): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                console.info("url: ", url)
                console.info("data: ", data)
                const httpRequest = await this.AxiosConfig();
                const response = await httpRequest.post(url, data)
                resolve(response?.data);
            } catch (error) {
                console.log("http-post-request: ", error);
                // Reject the promise with the error message or custom error object
                reject({ status: 400, message: "Something went wrong, please try again", data: null });
            }
        });
    }
}
