import Moment from 'react-moment';
import { AccountBalanceWallet } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { ProtectdPageWrapper } from '../../pages/_wrapper';
import React from 'react';
import { ThunkConfig } from '../../redux/config';

export function TransactionHistory() {

    const transactionHistory = localStorage.getItem("transactionHistory");
    const [transactionHistoryList, setTransactionHistoryList] = useState<any[]>(transactionHistory ? JSON.parse(transactionHistory) : [])

    const GetTransactionHistory = useCallback(async () => {
        const response = await ThunkConfig.HTTPGetRequest(`transactionHistory/get-all`);
        if (response) {
            localStorage.setItem("transactionHistory", JSON.stringify(response))
            setTransactionHistoryList(response)
        }
    }, [setTransactionHistoryList])

    useEffect(() => {
        GetTransactionHistory()
    }, [GetTransactionHistory])

    return (
        <ProtectdPageWrapper title='Transaction History'>
            <section className="shop-cart" style={{ margin: 0 }}>
                <div className="container">
                    {
                        transactionHistoryList?.map((item, index) => {
                            return (
                                <div key={index} className="card dashboard-card" style={{ color: 'white' }}>
                                    <div className="card-body">
                                        <ul className="report-list list-unstyled">
                                            <li className="report-item   report-danger ">
                                                <div className="report-icon">
                                                    <AccountBalanceWallet color='action' />
                                                </div>
                                                <div className="report-text">
                                                    {item.Actions}
                                                    <span>{item.Descriptions}</span>
                                                </div>
                                                <div className="report-helper"> <Moment fromNow>{item.ActionDate}</Moment></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
        </ProtectdPageWrapper>
    )
}