import React, { MouseEventHandler } from "react"
import { Link } from "react-router-dom"

type CustomNavLinkProps = {
    title: any,
    PageRoute: any,
    IconName: any,
    action?: MouseEventHandler
}

export const CustomNavLink = ({ title, PageRoute, IconName, action }: CustomNavLinkProps) => {
    return (
        <>
            <Link onClick={action} to={PageRoute} style={{ borderBottom: '10px', borderBottomColor: 'red', borderBottomWidth: '10px', color: 'white', marginBottom: 3, position: 'relative', display: 'flex', backgroundColor: window.location.pathname === PageRoute ? '#767676' : "#1d1d1d", padding: 15, paddingLeft: 25, cursor: 'pointer' }}>
                {IconName} {title}
            </Link>
            <hr style={{ margin: 0, padding: 0, backgroundColor: 'gray' }} />

        </>
    )
}