import React, { FormEvent, useCallback, useEffect, useState } from "react"
import { ProtectdPageWrapper } from "./_wrapper";
import Countries from "../data/countries.json"
import { Utils } from "../core/utils";
import { ThunkConfig } from "../redux/config";

const WalletModel = { Coin: '', Address: '', UserId: '' }
const ManageWalletModel = { WalletType: '', FundType: '', SendFundTo: '', Amount: 0, UserName: '', UserId: '' }

export const AccountSettings = () => {

    const role = localStorage.getItem("role")
    const userRole = Utils.DecryptData(role)
    const [userDetails, setUserDetails] = useState<any>(JSON.parse(localStorage.getItem("userDetails")))
    const [walletAddressList, setWalletAddressList] = useState<any[]>(localStorage.getItem("userWalletAddress") ? JSON.parse(localStorage.getItem("userWalletAddress")) : [])
    const [walletAddress, setWalletAddress] = useState(WalletModel)
    const [manageWallet, setManageWallet] = useState(ManageWalletModel);

    const [values, setValues] = useState<any>(userDetails)

    const GetWalletAddress = useCallback(async () => {
        try {

            const walletAddressLists = await ThunkConfig.HTTPGetRequest(`WalletAddress/get-user-wallet-address?userId=${userDetails?.id}`);
            setWalletAddressList(walletAddressList);
            localStorage.setItem("userWalletAddress", JSON.stringify(walletAddressLists));
        } catch (error) {

        }
    }, [userDetails, setWalletAddressList, walletAddressList])

    const SaveWalletAddress = useCallback(async (event: FormEvent) => {
        event.preventDefault();
        setWalletAddress(prev => ({ ...prev, UserId: userDetails?.userId }))
        try {
            if (!walletAddress.Address || !walletAddress.Coin) {
                Utils.AlertToast("Please fill in the required filed", "error", "Okay!", true)
                return;
            }
            const response = await ThunkConfig.HTTPPostRequest(`WalletAddress/save-data`, { ...walletAddress, userId: userDetails?.id });
            if (response && response?.statusCode === 200) {
                setWalletAddress(WalletModel)
                await GetWalletAddress();
            }
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
            Utils.AlertToast("Something went wrong, please try again!", "error", "Okay!", true)
        }

    }, [GetWalletAddress, setWalletAddress, walletAddress, userDetails])

    const OnDeleteWallet = useCallback(async (id: string) => {
        try {
            const response = await ThunkConfig.HTTPPostRequest(`WalletAddress/delete-wallet-address?id=${id}`, null);
            if (response.statusCode === 200) {
                Utils.AlertToast(response?.message, "error", "Okay!", true)
                await GetWalletAddress();
            }
        } catch (error) {
        }
    }, [GetWalletAddress])

    const OnFundWallet = async (event: FormEvent) => {
        event.preventDefault();
        manageWallet.UserId = userDetails?.userName;
        manageWallet.UserName = userDetails?.userName;
        const response = await ThunkConfig.HTTPPostRequest(`wallet/manage-fund-wallet`, manageWallet);
        if (response && response.statusCode === 200) {
            await GetWalletAddress();
        }
        setManageWallet(ManageWalletModel)
    }

    const UpdateProfile = useCallback(async (event: FormEvent) => {
        event.preventDefault();

        const response = await ThunkConfig.HTTPPostRequest("account/update-profile", values);
        if (response && response?.statusCode === 200) {
            localStorage.clear()
            window.location.href = "/account/login"
        }
        Utils.AlertToast(response?.message, "info", "Okay!", true)
    }, [values])

    useEffect(() => {
        setUserDetails(JSON.parse(localStorage.getItem("userDetails")));
        setWalletAddressList(localStorage.getItem("userWalletAddress") ? JSON.parse(localStorage.getItem("userWalletAddress")) : [])
        GetWalletAddress();
    }, [GetWalletAddress])

    return (
        <ProtectdPageWrapper title="Account Settings">
            <div className="container">
                <div className="row" style={{ paddingBottom: '100px', marginBottom: '120px' }}>
                    <div className="col-xs-12">
                        <form className="row" onSubmit={(event) => UpdateProfile(event)}>
                            <div className="col-xs-12 col-md-4">
                                <div className="form-group">
                                    <input type="text" value={values?.name} onChange={(e) => setValues((prev) => ({ ...prev, name: e.target.value }))} placeholder="FIRST NAME" className="form-control" />
                                </div>
                            </div>

                            <div className="col-xs-12 col-md-4">
                                <div className="form-group">
                                    <input type="email" value={values?.email} onChange={(e) => setValues((prev) => ({ ...prev, email: e.target.value }))} placeholder="EMAIL" className="form-control" />
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <div className="form-group">
                                    <input type="text" value={values?.userName} placeholder="USER NAME" className="form-control" />
                                </div>
                            </div>

                            <div className="col-xs-12 col-md-4">
                                <div className="form-group">
                                    <input type="text" value={values?.role} placeholder="ROLE" className="form-control" />
                                </div>
                            </div>

                            <div className="col-xs-12 col-md-4">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        value={values?.secreteQuestion}
                                        onChange={(e) => setValues((prev) => ({ ...prev, secreteQuestion: e.target.value }))}
                                        placeholder="SECRETE QUESTION"
                                        type="search"
                                        required />
                                </div>
                            </div>

                            <div className="col-xs-12 col-md-4">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        value={values?.secreteQuestionAnswer}
                                        onChange={(e) => setValues((prev) => ({ ...prev, secreteQuestionAnswer: e.target.value }))}
                                        placeholder="SECRETE QUESTION ANSWER"
                                        type="search"
                                        required />
                                </div>
                            </div>

                            <div className="col-xs-12 col-md-4">
                                <div className="form-group">
                                    <select value={values?.country} onChange={(e) => setValues((prev: any) => ({ ...prev, country: e.target.value }))} className="form-control" style={{ color: 'white' }}>
                                        <option selected disabled>please select country</option>
                                        {
                                            Countries.map((country, index) => {
                                                return (<option key={index} value={country.name}>{country.name} ({country.code})</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-8">
                                <button type="submit" className="btn btn-primary btn-order pull-right">Save Changes</button>
                            </div>
                        </form>

                        <div className="row" style={{ marginTop: 50, marginBottom: 90 }}>
                            <div className="col-md-12" style={{ marginTop: 20 }}>
                                <div className="form-group">
                                    <label>WALLET ADDRESS</label>
                                    <hr style={{ margin: 0, padding: 0 }} />
                                </div>
                            </div>

                            <div className="col-lg-12" style={{ margin: 0, padding: 0 }}>
                                <form className="row" onSubmit={(event) => SaveWalletAddress(event)}>
                                    <div className="col-xs-12 col-md-4">
                                        <div className="form-group">
                                            <select
                                                onChange={(e) => { setWalletAddress((prev) => ({ ...prev, Coin: e.target.value })) }}
                                                value={walletAddress?.Coin}
                                                required
                                                className="form-control" style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                                                <option selected disabled value="" style={{ color: 'silver' }}>-- select wallet type --</option>
                                                <option value="Bitcoin">Bitcoin</option>
                                                <option value="Ethereum">Ethereum</option>
                                                <option value="Tether USDT">Tether USDT</option>
                                                <option value="Binance">Binance</option>
                                                {/* <option value="Reinvest">Reinvest with balance</option> */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-md-7">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                onChange={(e) => { setWalletAddress((prev) => ({ ...prev, Address: e.target.value })) }}
                                                value={walletAddress?.Address}
                                                placeholder="WALLET ADDRESS"
                                                className="form-control"
                                                style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}
                                                required />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-md-1">
                                        <button type="submit" className="btn btn-primary btn-order pull-right">Save</button>
                                    </div>
                                </form>
                            </div>

                            <div className="col-md-12 table-responsive" style={{ marginTop: 30 }}>
                                <table className="table order text-center" >
                                    <thead>
                                        <tr>
                                            <th style={{ paddingLeft: 20 }}>S/N</th>
                                            <th>Wallet Type</th>
                                            <th>Address</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            walletAddressList?.map((item, index) => {
                                                console.log(`deposit-${index + 1}}: `, item)
                                                return (
                                                    <tr key={index} style={{ color: 'black' }}>
                                                        <td className="text-left" style={{ paddingLeft: 20 }}><span className="price">{index + 1}.</span></td>
                                                        <td className="text-left"><span className="price">{item?.coin}</span></td>
                                                        <td className="text-left"><span className="price">{item?.address}</span></td>
                                                        <td className="text-left">
                                                            <label onClick={() => OnDeleteWallet(item?.id)} style={{ color: 'red', cursor: 'pointer' }}>Delete</label>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                {!walletAddressList || walletAddressList?.length === 0 ? <h3 className="text-center" style={{ color: 'silver' }}>You've not added your wallet address!</h3> : null}
                            </div>
                        </div>

                        {localStorage.getItem("accessToken") && userRole?.toLowerCase() === "admin" ?

                            <form onSubmit={(event) => OnFundWallet(event)} className="row" style={{ marginTop: 80, marginBottom: 100 }}>
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label>MANAGE FUNDS</label>
                                        <hr style={{ margin: 0, padding: 0 }} />
                                    </div>
                                </div>

                                <div className="col-md-7">
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label active">Select Wallet:</label>
                                        <div className="col-sm-8">
                                            <select value={manageWallet?.WalletType} onChange={(e) => setManageWallet({ ...manageWallet, WalletType: e.target.value })} className="form-control" style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                                                <option value="" selected disabled>Choose Option</option>
                                                <option value="Bitcoin">Bitcoin</option>
                                                <option value="Other Wallet">Other Wallet</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-7">
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label active">Select Fund Type:</label>
                                        <div className="col-sm-8">
                                            <select value={manageWallet?.FundType} onChange={(e) => setManageWallet({ ...manageWallet, FundType: e.target.value })} className="form-control" style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                                                <option value="" selected disabled>Choose Option</option>
                                                <option value="Add">Add</option>
                                                <option value="Add">Substract</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-7">
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label active">Send fund to:</label>
                                        <div className="col-sm-8">
                                            <select value={manageWallet?.SendFundTo} onChange={(e) => setManageWallet({ ...manageWallet, SendFundTo: e.target.value })} className="form-control" style={{ backgroundColor: 'white', color: 'black', fontSize: 18, fontWeight: 'bolder', borderColor: 'silver' }}>
                                                <option value="" selected disabled>Choose Option</option>
                                                <option value="Balance">Balance</option>
                                                <option value="Profits">Profits</option>
                                                <option value="ReferralBonus">Referral Bonus</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-7">
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label active">Amount: </label>
                                        <div className="col-sm-8">
                                            <input value={manageWallet?.Amount} onChange={(e: any) => setManageWallet({ ...manageWallet, Amount: Number(e.target.value?.replaceAll(/[^0-9]/g, "")) })} className="form-control" type="search" placeholder="Enter Amount" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-7">
                                    <button type="submit" className="btn btn-primary btn-order pull-right">Submit</button>
                                </div>

                            </form>
                            : null}
                    </div>
                </div>
            </div>
        </ProtectdPageWrapper>
    )
}