import { Utils } from "../core/utils";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export function ProtectedRoutes() {
    return localStorage.getItem("accessToken") ? <Outlet /> : <Navigate to="/account/login" />;
};

export function AuthenticationRoutes() {
    return !localStorage.getItem("accessToken") ? <Outlet /> : <Navigate to="/dashboard" />;
};

export function AdminRoutes() {
    return localStorage.getItem("accessToken") &&  Utils.DecryptData(localStorage.getItem("role"))?.toLowerCase() === "admin" ? <Outlet /> : <Navigate to="/dashboard" />;
};