import React, { useState, useRef, useCallback, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { TextField, MenuItem } from '@mui/material';
import { Edit, Delete } from "@mui/icons-material";
import { ThunkConfig } from '../../redux/config';
import CustomAlert from '../../shared/custom-alert';
import CustomDialog from '../../shared/custom-dialog';
import CustomModal from '../../shared/custom-modal';
import { ProtectdPageWrapper } from '../../pages/_wrapper';
import { Utils } from '../../core/utils';


export function PlansSettings() {

    const [messages, setMessages] = useState<any>();
    const [isBusy, setBusy] = useState(false);
    const [values, setValues] = useState<any>();

    const compoundings = localStorage.getItem("compoundings")
    const [compoundingList, setCompoundingList] = useState<any[]>(compoundings ? JSON.parse(compoundings) : [])

    const planSettings = localStorage.getItem("planSettings")
    const [planSettingList, setPlanSettingList] = useState<any[]>(planSettings ? JSON.parse(planSettings) : [])

    const GetCompoundings = useCallback(async () => {
        const response = await ThunkConfig.HTTPGetRequest(`compounding/get-all`);
        if (response) {
            localStorage.setItem("compoundings", JSON.stringify(response))
            setCompoundingList(response)
        }
    }, [])

    const GetPlanSettings = useCallback(async () => {
        const response = await ThunkConfig.HTTPGetRequest(`plans/get-all`);
        if (response) {
            localStorage.setItem("planSettings", JSON.stringify(response))
            setPlanSettingList(response)
        }
    }, [])

    const alertRef = useRef(null);
    const dialogRef = useRef(null);
    const modalRef = useRef(null);

    const OnOpenModal = () => {
        setValues(null);
        modalRef.current.toggleModal();
    }

    const OnEditPlan = (item: any) => {
        setValues(item);
        modalRef.current.toggleModal();
    }

    const OnDeleteRequest = (item, name) => {
        try {
            setBusy(true);
            let newMessage = <><b className='mb-4'>{name}, will be deleted.</b> <br /> <em className='text-danger'>Are you sure?</em></>;

            setMessages(newMessage);
            setValues(item);
            setBusy(false);
            dialogRef.current.toggleDialog();
        } catch (error) {
        }
    }

    const OnDelete = async () => {
        try {
            setBusy(true);
            const response = await ThunkConfig.HTTPPostRequest(`plans/delete?id=${values?.id}`, null);
            if (response && response?.statusCode === 200) {
                await GetPlanSettings();
            }
            dialogRef.current.toggleDialog();
            setBusy(false);
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
            setBusy(false);
        }
    }

    const OnToggle = useCallback(async (id: string) => {
        try {
            setBusy(true);
            //
            const response = await ThunkConfig.HTTPPostRequest(`plans/toggle?id=${id}`, null);
            if (response) {
                await GetPlanSettings();
            }
            setBusy(false);
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
            setBusy(false);
        }
    }, [GetPlanSettings])

    const SaveData = useCallback(async () => {
        try {
            setBusy(true);
            const response = await ThunkConfig.HTTPPostRequest(`plans/form-data`, values);
            if (response && response?.statusCode === 200) {
                await GetPlanSettings();
                setValues(null);
                modalRef.current.toggleModal();
            }
            setBusy(false);
            Utils.AlertToast(response?.message, "info", "Okay!", true)
        } catch (error) {
        }
    }, [values, GetPlanSettings])


    useEffect(() => {
        GetCompoundings()
        GetPlanSettings();
    }, [GetCompoundings, GetPlanSettings])

    return (
        <ProtectdPageWrapper title='Manage Plans'>

            <section className="shop-cart" style={{ margin: 0 }}>
                <div className="container">
                    <div className='row mb-5'>
                        <div className='col-md-6'><h2>Manage Plans</h2></div>
                        <div className='col-md-6 pull-right'><button disabled={isBusy} className='btn btn-success pull-right' onClick={OnOpenModal} style={{ width: 100 }}>New Plan</button></div>
                    </div>
                    <div className="col-xs-12 table-responsive">
                        <table className="table order text-center" >
                            <thead>
                                <tr>
                                    <th style={{ paddingLeft: 20 }}>S/N</th>
                                    <th>Plan Name</th>
                                    <th>Percentage</th>
                                    <th>Compounding</th>
                                    <th>Min</th>
                                    <th>Max</th>
                                    <th>Status</th>
                                    <th colSpan={2}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    planSettingList?.map((item: any, index) => {
                                        return (
                                            <tr key={index} style={{ color: 'black' }}>
                                                <td className="text-left" style={{ paddingLeft: 20 }}><span className="price">{index + 1}.</span></td>
                                                <td className="text-left"><span className="price">{item?.planName}</span></td>
                                                <td className="text-left"><span className="price">{item?.percentage}%</span></td>
                                                <td className="text-left"><span className="price">{item?.compounding}</span></td>
                                                <td className="text-left"><span className="price">{item?.minimum}</span></td>
                                                <td className="text-left"><span className="price">{item?.maximum}</span></td>
                                                <td className="text-left">
                                                    <span className="price"><FormControlLabel control={<Switch color='success' disabled={isBusy} checked={item.isActive} onChange={() => OnToggle(item.id)} />} label={item.isActive ? "Active" : "Disabled"} /></span>
                                                </td>
                                                <td className="text-left">
                                                    <Edit style={{ width: 30, height: 30, cursor: 'pointer' }} className='mr-4' color="primary" onClick={() => OnEditPlan(item)} />
                                                </td>
                                                <td className="text-left">
                                                    <Delete style={{ width: 30, height: 30, cursor: 'pointer' }} color="error" onClick={() => OnDeleteRequest(item, item.planName)} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {planSettingList.length === 0 && <h3 className="text-center" style={{ color: 'silver' }}>No plan settings has been setup yet</h3>}
                    </div>
                </div>
            </section>

            <CustomModal ref={modalRef} width={'600px'} height={'200px'} title={'Plan Settings'} isBusy={false}>

                <div className="row p-3 d-flex align-content-center align-items-center justify-content-between">

                    <div className="col-md-12">
                        <TextField value={values?.planName} onChange={(e) => { setValues({ ...values, planName: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" label="Plan Name" />
                    </div>

                    <div className="col-md-12">
                        <TextField type="number" value={values?.percentage} onChange={(e) => { setValues({ ...values, percentage: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" label="Percentage" />
                    </div>

                    <div className="col-md-12">
                        <TextField type="number" value={values?.minimum} onChange={(e) => { setValues({ ...values, minimum: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" label="Minimum" />
                    </div>

                    <div className="col-md-12">
                        <TextField type="number" value={values?.maximum} onChange={(e) => { setValues({ ...values, maximum: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" label="Maximum" />
                    </div>

                    <div className="col-md-12 mt-4" style={{ width: '100%' }}>
                        <TextField value={values?.compoundingId} onChange={(e) => { setValues({ ...values, compoundingId: e.target.value }) }} sx={{ width: '100%' }} id="WithdrawalTypes" label="Compounding" select  >
                            {
                                compoundingList && compoundingList.length > 0 ?
                                    compoundingList?.map((item, index) => {
                                        return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    })
                                    : null
                            }
                        </TextField>
                    </div>

                    <div className="col-md-12" style={{ marginTop: 20 }}>
                        <button disabled={isBusy} onClick={() => SaveData()} type="submit" className="btn btn-primary btn-order pull-right">{isBusy ? "Saving..." : "Save"}</button>
                    </div>

                </div>

            </CustomModal>

            <CustomDialog ref={dialogRef} >
                <h4 className='text-success text-center mb-5 mt-3'>{messages}</h4>
                <div className='row'>
                    <div className='col-md-6'><button disabled={isBusy} className='btn btn-default' onClick={() => dialogRef.current.toggleDialog()}>No</button></div>
                    <div className='col-md-6 text-right'>
                        <button disabled={isBusy} onClick={() => OnDelete()} type="submit" className="btn btn-primary btn-order pull-right">{isBusy ? "Deleting..." : "Yes"}</button>
                    </div>
                </div>
            </CustomDialog>

            <CustomAlert ref={alertRef}>{messages}</CustomAlert>
        </ProtectdPageWrapper>
    );
}