import React, { useCallback, useEffect, useState } from "react"
import { TextField, Paper } from '@mui/material';
import { ProtectdPageWrapper } from "../../pages/_wrapper";
import { Utils } from "../../core/utils";
import { ThunkConfig } from "../../redux/config";

export const BasicSettings = () => {

    const [isBusy, setBusy] = useState(false);
    const _siteSettings = localStorage.getItem("siteSettings");
    const [siteSettings, setSiteSettings] = useState<any>(_siteSettings ? JSON.parse(_siteSettings) : null);

    const SaveData = async () => {
        try {
            setBusy(true)
            const response = await ThunkConfig.HTTPPostRequest(`basicsettings/form-data`, siteSettings);
            if (response?.statusCode === 200) {
                await GetSiteSettings();
            }
            await Utils.TaskDelay();

            Utils.AlertToast(response?.message, "info", "Okay!", true)
            setBusy(false);
        } catch (error) {
            setBusy(false);
        }
    }

    const GetSiteSettings = useCallback(async () => {
        const response = await ThunkConfig.HTTPGetRequest(`basicsettings/get-setting`);
        if (response) {
            localStorage.setItem("siteSettings", JSON.stringify(response))
            setSiteSettings(response)
        }
    }, [])

    useEffect(() => {
        GetSiteSettings()
    }, [GetSiteSettings])

    return (
        <ProtectdPageWrapper title="Site Settings">
            <div style={{ margin: '10%' }}>
                <Paper elevation={3} sx={{ mt: 1, p: 5 }}>

                    <div className="row p-3 d-flex align-content-center align-items-center justify-content-between">

                        <div className="col-md-12">
                            <TextField value={siteSettings?.siteName} onChange={(e) => { setSiteSettings({ ...siteSettings, siteName: e.target.value }) }} sx={{ fontSize: '26px', width: '100%', my: 2 }} placeholder="Site Name" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={siteSettings?.sitePhone} onChange={(e) => { setSiteSettings({ ...siteSettings, sitePhone: e.target.value }) }} sx={{ width: '100%', my: 2 }} id="standard-basic" placeholder="Site Phone Number" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={siteSettings?.siteUrl} onChange={(e) => { setSiteSettings({ ...siteSettings, siteUrl: e.target.value }) }} sx={{ width: '100%', my: 2 }} id="standard-basic" placeholder="Site Url"/>
                        </div>

                        <div className="col-md-12">
                            <TextField value={siteSettings?.siteEmail} onChange={(e) => { setSiteSettings({ ...siteSettings, siteEmail: e.target.value }) }} sx={{ width: '100%', my: 2 }} id="standard-basic" placeholder="Site Email" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={siteSettings?.siteEmail} onChange={(e) => { setSiteSettings({ ...siteSettings, siteEmail: e.target.value }) }} sx={{ width: '100%', my: 2 }} id="standard-basic" placeholder="Site Send Notify Email" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={siteSettings?.refPercentage} onChange={(e) => { setSiteSettings({ ...siteSettings, refPercentage: e.target.value }) }} sx={{ width: '100%', my: 2 }} id="standard-basic" placeholder="Referral Percentage" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={siteSettings?.address} onChange={(e) => { setSiteSettings({ ...siteSettings, address: e.target.value }) }} sx={{ width: '100%', my: 2 }} id="standard-basic" placeholder="Address" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={siteSettings?.siteCode} onChange={(e) => { setSiteSettings({ ...siteSettings, siteCode: e.target.value }) }} sx={{ width: '100%', my: 2 }} id="standard-basic" placeholder="Site Short Code Name" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={siteSettings?.location} onChange={(e) => { setSiteSettings({ ...siteSettings, location: e.target.value }) }} sx={{ width: '100%', my: 2 }} id="standard-basic" placeholder="Location" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={siteSettings?.videoLink} onChange={(e) => { setSiteSettings({ ...siteSettings, videoLink: e.target.value }) }} sx={{ width: '100%', my: 2 }} id="standard-basic" placeholder="Video Link" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={siteSettings?.depositInvestmentCharge} onChange={(e) => { setSiteSettings({ ...siteSettings, depositInvestmentCharge: e.target.value }) }} sx={{ width: '100%', my: 2 }} id="standard-basic" placeholder="Deposit Charge" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={siteSettings?.withdrawCharge} onChange={(e) => { setSiteSettings({ ...siteSettings, withdrawCharge: e.target.value }) }} sx={{ width: '100%', my: 2 }} id="standard-basic" placeholder="Withdraw Charge" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={siteSettings?.blockIoPin} onChange={(e) => { setSiteSettings({ ...siteSettings, blockIoPin: e.target.value }) }} sx={{ width: '100%', my: 2 }} id="standard-basic" placeholder="Block IO Secrent Pin" />
                        </div>

                        <div className="col-md-12">
                            <button type="submit" disabled={isBusy} onClick={() => SaveData()} className="btn btn-primary btn-order pull-right">{isBusy ? "Please wait..." : "Save"}</button>
                        </div>

                    </div>

                </Paper>

            </div>

        </ProtectdPageWrapper>
    )
}