import React, { useState, useCallback, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Moment from 'react-moment';
import { ThunkConfig } from '../../redux/config';
import { Utils } from '../../core/utils';
import { Button } from '@mui/material';
import { ProtectdPageWrapper } from '../../pages/_wrapper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export function ManageWithdrawal() {
    const [isBusy, setBusy] = useState(false);
    const [withdrawalRequestList, setWithdrawalRequestList] = useState<any[]>(localStorage.getItem("withdrawalRequest") as any)

    const GetWithdrawalRequest = useCallback(async () => {
        const response = await ThunkConfig.HTTPGetRequest(`withdrawal/get-all`);
        if (response) {
            localStorage.setItem("withdrawalRequest", response)
            setWithdrawalRequestList(response)
        }
    }, [])

    useEffect(() => {
        GetWithdrawalRequest()
    }, [GetWithdrawalRequest])

    let count = 1;

    const OnConfirmWithdrawalRequest = useCallback(async (id: any) => {
        setBusy(true);
        const response = await ThunkConfig.HTTPPostRequest(`withdrawal/confirm-request?id=${id}`, null);
        if (response && response.statusCode === 200) {
            await GetWithdrawalRequest()
        }
        setBusy(false);
        Utils.AlertToast(response?.message, "info", "Okay!", true)

    }, [GetWithdrawalRequest])

    const OnFundUserBitcoinWallet = async (id: any) => {
        setBusy(true);
        const response = await ThunkConfig.HTTPPostRequest(`wallet/fund-user-bitcoin-wallet?withdrawalId=${id}`, null);
        if (response && response.statusCode === 200) {
            await GetWithdrawalRequest()
        }
        setBusy(false);
        Utils.AlertToast(response?.message, "info", "Okay!", true)

    }

    return (
        <ProtectdPageWrapper title='Manage Withdrawals'>
            <section className="shop-cart" style={{ margin: 0 }}>
                <div className="container">
                    <div className="col-xs-12 table-responsive">
                        <table className="table order text-center" >
                            <thead>
                                <tr>
                                    <th style={{ paddingLeft: 20, paddingRight: 20, minWidth:'20px' }}>S/N</th>
                                    <th style={{ minWidth: '200px' }}>Transaction Id</th>
                                    <th style={{ minWidth: '200px' }}>User</th>
                                    <th style={{ minWidth: '200px' }}>Wallet Type</th>
                                    <th style={{ minWidth: '200px' }}>Address</th>
                                    <th style={{ minWidth: '200px' }}>Amount</th>
                                    <th style={{ minWidth: '200px' }}>Charge</th>
                                    <th style={{ minWidth: '200px' }}>Amount to Pay</th>
                                    <th style={{ minWidth: '200px' }}>Withdraw from</th>
                                    <th style={{ minWidth: '200px' }}>Status</th>
                                    <th colSpan={3} style={{ minWidth: '200px' }}>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    withdrawalRequestList?.map((item: any, index) => {
                                        return (
                                            <tr key={index} style={{ color: 'black' }}>
                                                <td className="text-left" style={{ paddingLeft: 20 }}><span className="price">{index + 1}.</span></td>
                                                <td className="text-left"><span className="price">{item?.TransactionId}</span></td>
                                                <td className="text-left"><span className="price">{item?.UserId}</span></td>
                                                <td className="text-left"><span className="price">{item?.WalletType}</span></td>
                                                <td className="text-left"><span className="price">{item?.BitcoinAddress}</span></td>
                                                <td className="text-left"><span className="price">{Utils.Currency.format(item?.Amount ? item?.Amount : 0)}</span></td>
                                                <td className="text-left"><span className="price">{Utils.Currency.format(item?.Charge ? item?.Charge : 0)}</span></td>
                                                <td className="text-left"><span className="price">{Utils.Currency.format(item?.AmountToPay ? item?.AmountToPay : 0)}</span></td>
                                                <td className="text-left"><span className="price">{item.WithdrawalType}</span></td>
                                                <td className="text-left"><span className="price">{item.Status}</span></td>
                                                <td className="text-left"><span className="price"><Moment fromNow>{item.ActionDate}</Moment></span></td>
                                                <td className="text-left">
                                                    {
                                                        item.Status === "Pending" ?
                                                            <>
                                                                <Button
                                                                    disabled={isBusy}
                                                                    color="success"
                                                                    onClick={() => OnConfirmWithdrawalRequest(item.Id)}
                                                                    // loading={isBusy}
                                                                    variant="contained"
                                                                    sx={{ my: 0, float: 'right', px: 2, py: 1 }}
                                                                >
                                                                    <span>Confirm</span>
                                                                </Button>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        item.Status === "Confirmed" ?
                                                            <Button
                                                                disabled={isBusy}
                                                                color="primary"
                                                                onClick={() => OnFundUserBitcoinWallet(item.Id)}
                                                                variant="contained"
                                                                sx={{ my: 0, float: 'right', px: 2, py: 1 }}
                                                            >
                                                                <span>Transfer</span>
                                                            </Button>
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {withdrawalRequestList?.length === 0 && <h3 className="text-center" style={{ color: 'silver' }}>No withdrawal request yet</h3>}
                    </div>
                </div>
            </section>
        </ProtectdPageWrapper >
    );
}