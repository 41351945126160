/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react"
import { Link } from "react-router-dom"

export const Footer = () => {

    const [selectedFAQs, setSelectedFAQs] = useState<any>()

    return (
        <>
            {!JSON.parse(localStorage.getItem("userDetails")) &&

                <>

                    <section className="container">
                        <div className="center" style={{ flexDirection: 'column', justifyContent: 'center', marginTop: '10%', alignItems: 'center' }}>
                            <div className="action-text">
                                <h2>Start investing today</h2>
                                <p className="lead">Open account for free and start investing!</p>
                                <p className="action-btn"><Link className="btn btn-primary" to="/account/register">Register Now</Link></p>
                            </div>
                        </div>
                    </section>

                    <section className="call-action-all">
                        <div className="call-action-all-overlay">
                            <div className="col-xs-12">
                                <div className="action-text">
                                    <h2>Slash Coins FAQs</h2>
                                    <p className="lead">Find answers to frequently asked questions about slashcoins investments.</p>
                                </div>

                                <div onClick={() => setSelectedFAQs("cryptocurrency investment")} style={{ cursor: 'pointer', paddingLeft: "5%", paddingRight: "5%", paddingTop: '1%', paddingBottom: '1%', width: '100%', position: 'relative', marginBottom: "10px", backgroundColor: 'white' }}>
                                    <h3>{selectedFAQs === "cryptocurrency investment" ? <span style={{ color: 'gray' }}>&#9660;</span> : <span style={{ color: 'gray' }}>&#9650;</span>} What is your cryptocurrency investment platform about?</h3>
                                    {selectedFAQs === "cryptocurrency investment" && <p style={{ color: 'black' }}>Our platform is a place for everyone who wants to simply invest in cryptocurrency. Our investment professionals manage savers' funds by investing them in various financial instruments to ensure optimal returns.</p>}
                                </div>

                                <div onClick={() => setSelectedFAQs("security of savers")} style={{ cursor: 'pointer', paddingLeft: "5%", paddingRight: "5%", paddingTop: '1%', paddingBottom: '1%', width: '100%', position: 'relative', marginBottom: "10px", backgroundColor: 'white' }}>
                                    <h3>{selectedFAQs === "security of savers" ? <span style={{ color: 'gray' }}>&#9660;</span> : <span style={{ color: 'gray' }}>&#9650;</span>} How do you ensure the security of savers' funds?</h3>
                                    {selectedFAQs === "security of savers" && <p style={{ color: 'black' }}>We take the security of savers' funds very seriously. To ensure this, our company is registered with the Security and Exchange Commission (SEC) on behalf of savers. Additionally, these assets are regularly marked to market to ensure that savers' interests are protected.</p>}
                                </div>

                                <div onClick={() => setSelectedFAQs("investment professionals")} style={{ cursor: 'pointer', paddingLeft: "5%", paddingRight: "5%", paddingTop: '1%', paddingBottom: '1%', width: '100%', position: 'relative', marginBottom: "10px", backgroundColor: 'white' }}>
                                    <h3>{selectedFAQs === "investment professionals" ? <span style={{ color: 'gray' }}>&#9660;</span> : <span style={{ color: 'gray' }}>&#9650;</span>} What measures do you take to manage investments effectively?</h3>
                                    {selectedFAQs === "investment professionals" && <p style={{ color: 'black' }}>Our investment professionals employ a range of strategies to manage investments effectively. These include thorough research, diversification, and staying abreast of market trends to make informed decisions aimed at achieving the best possible returns for savers.</p>}
                                </div>

                                <div onClick={() => setSelectedFAQs("monitored continuously")} style={{ cursor: 'pointer', paddingLeft: "5%", paddingRight: "5%", paddingTop: '1%', paddingBottom: '1%', width: '100%', position: 'relative', marginBottom: "10px", backgroundColor: 'white' }}>
                                    <h3>{selectedFAQs === "monitored continuously" ? <span style={{ color: 'gray' }}>&#9660;</span> : <span style={{ color: 'gray' }}>&#9650;</span>} How often are savers' funds monitored for security and performance?</h3>
                                    {selectedFAQs === "monitored continuously" && <p style={{ color: 'black' }}>Savers' funds are monitored continuously to ensure both security and performance. Additionally, assets are marked to market periodically to provide transparent valuation and ensure that savers' investments are safeguarded.</p>}
                                </div>

                                <div onClick={() => setSelectedFAQs("platform is regulated ")} style={{ cursor: 'pointer', paddingLeft: "5%", paddingRight: "5%", paddingTop: '1%', paddingBottom: '1%', width: '100%', position: 'relative', marginBottom: "10px", backgroundColor: 'white' }}>
                                    <h3>{selectedFAQs === "platform is regulated " ? <span style={{ color: 'gray' }}>&#9660;</span> : <span style={{ color: 'gray' }}>&#9650;</span>} Is your platform regulated?</h3>
                                    {selectedFAQs === "platform is regulated " && <p style={{ color: 'black' }}>Yes, our platform is regulated by the Security and Exchange Commission (SEC), providing an additional layer of security and transparency for savers' investments.</p>}
                                </div>

                            </div>

                        </div>
                    </section>

                </>
            }
            <footer className="footer">
                {!window.location.pathname.includes("dashboard") &&

                    <div className="top-footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-4 col-md-4">
                                    <h4>Our Company</h4>
                                    <div className="menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/about">About</Link></li>
                                            <li><Link to="/investment-plans">Investment Plans</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-3">
                                    <h4>Contact Us </h4>
                                    <div className="contacts">
                                        <div>
                                            <span>{process.env.REACT_APP_EMAIL_ADDRESS}</span>
                                        </div>
                                        <div>
                                            <span>{process.env.REACT_APP_PHONUMBER}</span>
                                        </div>
                                        <div>
                                            <span>{process.env.REACT_APP_OFFICE_ADDRESS}</span>
                                        </div>
                                        <div>
                                            <span>mon-sat 08am &#x21FE; 05pm</span>
                                        </div>
                                    </div>
                                    <div className="social-footer">
                                        <ul>
                                            <li><a href="/" target="_blank"><i className="fa fa-facebook">.</i></a></li>
                                            <li><a href="/" target="_blank"><i className="fa fa-twitter">.</i></a></li>
                                            <li><a href="/" target="_blank"><i className="fa fa-google-plus">.</i></a></li>
                                            <li><a href="/" target="_blank"><i className="fa fa-linkedin"></i>.</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-5">
                                    <div className="facts-footer">
                                        <div>
                                            <h5>$198.76B</h5>
                                            <span>Market cap</span>
                                        </div>
                                        <div>
                                            <h5>243K</h5>
                                            <span>daily transactions</span>
                                        </div>
                                        <div>
                                            <h5>369K</h5>
                                            <span>active accounts</span>
                                        </div>
                                        <div>
                                            <h5>127</h5>
                                            <span>supported countries</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="payment-logos">
                                        <h4 className="payment-title">supported payment methods</h4>
                                        <img src="images/icons/payment/american-express.png" alt="american-express" />
                                        <img src="images/icons/payment/mastercard.png" alt="mastercard" />
                                        <img src="images/icons/payment/visa.png" alt="visa" />
                                        <img src="images/icons/payment/paypal.png" alt="paypal" />
                                        <img className="last" src="images/icons/payment/maestro.png" alt="maestro" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="bottom-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <p className="text-center">Copyright © 2018 {process.env.REACT_APP_APP_NAME} All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a href="/#" id="back-to-top" className="back-to-top fa fa-arrow-up">.</a>
        </>
    )
}