import React, { useEffect, useState } from "react"
import Moment from 'react-moment';
import { ProtectdPageWrapper } from "../_wrapper"
import { ThunkConfig } from "../../redux/config";
import { Utils } from "../../core/utils";
import { Link } from "react-router-dom";

export const ClientDeposits = () => {

    const [depositList, setDepositList] = useState<any[]>(JSON.parse(localStorage.getItem("userInvestment")))

    const GetUserInvestments = async () => {
        const userInvestment: any[] = await ThunkConfig.HTTPGetRequest(`invests/get-user-investment`);
        localStorage.setItem("userInvestment", JSON.stringify(userInvestment))
        setDepositList(userInvestment)
    }

    useEffect(() => {
        GetUserInvestments();
    }, [])

    return (
        <ProtectdPageWrapper title="Deposits">
            <section className="shop-cart" style={{ margin: 0 }}>
                <div className="container">
                    <div className='row mb-5'>
                        <div className='col-md-6'><h2>Manage deposits</h2></div>
                        <div className='col-md-6 pull-right'><Link to="/dashboard/investment-plans" className='btn btn-success pull-right' style={{ width: 100 }}>New Deposit</Link></div>
                    </div>
                    <div className="col-xs-12 table-responsive">
                        <table className="table order text-center" >
                            <thead>
                                <tr>
                                    <th style={{ paddingLeft: 20 }}>S/N</th>
                                    <th>Transaction Id</th>
                                    <th>Plan</th>
                                    <th>Wallet Type</th>
                                    <th>Interest</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    depositList?.map((item: any, index) => {
                                        console.log(`deposit-${index + 1}}: `, item)
                                        return (
                                            <tr key={index} style={{ color: 'black' }}>
                                                <td className="text-left" style={{ paddingLeft: 20 }}><span className="price">{index + 1}.</span></td>
                                                <td className="text-left"><h6 className="product">{item?.TransactionId}</h6></td>
                                                <td className="text-left"><span className="price">{item?.PlanId}</span></td>
                                                <td className="text-left"><span className="price">{item?.WalletType}</span></td>
                                                <td className="text-left"><span className="price">{item?.Percentage}%</span></td>
                                                <td className="text-left"><span className="price" style={{ color: 'lightgreen' }}>{Utils.Currency.format(item.Amount)}</span></td>
                                                <td className="text-left"><span className="price" style={{ color: item?.Status === "Pending" ? "red" : 'lightgreen' }}>{item?.Status}</span></td>
                                                <td className="text-left"><span className="price"><Moment fromNow>{item.ActionDate}</Moment> </span></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {!depositList || depositList?.length === 0 ? <h3 className="text-center" style={{ color: 'silver' }}>No withdrawal record found!</h3> : null}
                    </div>
                </div>
            </section>
        </ProtectdPageWrapper>
    )
}