import Swal from "sweetalert2";
import CryptoJS from 'crypto-js';

const LoadScript = () => {
    const script = document.createElement('script');
    script.src = `${process.env.PUBLIC_URL}/js/jquery-3.2.1.min.js`;
    script.src = `${process.env.PUBLIC_URL}/styles/bootstrap4/popper.js`;
    script.src = `${process.env.PUBLIC_URL}/styles/bootstrap4/bootstrap.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/greensock/TweenMax.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/greensock/TimelineMax.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/scrollmagic/ScrollMagic.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/greensock/animation.gsap.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/greensock/ScrollToPlugin.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/OwlCarousel2-2.2.1/owl.carousel.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/easing/easing.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/video-js/video.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/video-js/Youtube.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/parallax-js-master/parallax.min.js`;
    script.src = `${process.env.PUBLIC_URL}/js/custom.js`;
    script.async = true;
    document.body.appendChild(script);
}

const api = "https://server.kapitol-investments.censpophed.com/";
// let api = "https://localhost:44317/";
const BaseUrl = `${api}api/`;
const PathUrl = api

export const Utils = {
    AppName: "Kapitol",

    LoadScript: LoadScript(),

    NotificationToast: Swal.mixin({
        toast: true,
        position: 'top-end',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
    }),

    AlertToast: (text: any, icon: any, btnText: any, showConfirmButton: boolean) => {
        return Swal.fire({
            text: text,
            icon: icon,
            confirmButtonText: btnText,
            allowOutsideClick: false,
            showConfirmButton: showConfirmButton,
            buttonsStyling: true,
        })
    },

    DelayDuration: 2000,
    TaskDelay: () => new Promise(resolve => setTimeout(resolve, Utils.DelayDuration)),
    // let api = "https://localhost:44317/";
    BaseUrl: BaseUrl,
    PathUrl: PathUrl,
    AuthKey: "lQ@OsBFth1N#YBCA^CMUr$78x3Zu+n0xdI364%0sUjW/j36KOulT-msD?25_efkGi",

    Currency: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }),

    TruncateString: (length, value) => {
        let newValue = value !== null ? value?.substring(0, length) : "Start Chat";
        return newValue !== null && newValue?.length === value?.length ? newValue : `${newValue}...`
    },

    EncryptData: (data: any) => {
        if (!data) return;
        // Encrypt data using AES encryption with a secret key
        const encryptedData = CryptoJS.AES.encrypt(data, process.env.REACT_APP_ENCRYPTION_KEY).toString();
        return encryptedData;
    },

    DecryptData: (encryptedData: string): string => {
        if (!encryptedData) return;
        // Decrypt data using AES decryption with a secret key
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_ENCRYPTION_KEY);
        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return decryptedData;
    },

    CopyToClipboard: (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                if (!('Notification' in window)) {
                    Utils.AlertToast("This browser does not support desktop notification", "error", "Okay!", true)
                } else if (Notification.permission === 'granted') {
                    new Notification(process.env.REACT_APP_APP_NAME, {
                        body: 'Copied to clipboard',
                        icon: null // Optional: You can provide a path to an icon
                    });
                } else if (Notification.permission !== 'denied') {
                    Notification.requestPermission().then((permission) => {
                        if (permission === 'granted') {
                            new Notification(process.env.REACT_APP_APP_NAME, {
                                body: 'Copied to clipboard',
                                icon: null // Optional: You can provide a path to an icon
                            });
                        }
                    });
                }
            })
            .catch((error) => {
                console.error('Error copying to clipboard: ', error);
            });
    },

}